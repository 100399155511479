/*BELOW CODE FOR PASSWORD PAGE*/
.pwd_content{
	background-color: var(--secondarycolor);
	@include border-radius(10px);
	margin: auto;
	padding: 50px;
	width: 100%;
	background-color: var(--whitecolor);
	&.half_width{
		max-width: 540px;
	}
	.title_block{
		margin: 0 0 30px;
		text-align: center;
		.headtitle{
			color: var(--blackcolor);
			font-size: var(--fs40);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 48px;
		}
		.maintitle{
			color: var(--blackcolor);
			font-size: var(--fs28);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 33px;
			margin: 0 0 30px;
		}
		.desc{
			color: var(--blackcolorone);
			font-size: var(--fs20);
			font-family:  var(--fffira);
			line-height: 24px;	
			&:not(:last-child){
				margin: 0 0 20px;
			}
		}
		.link{
			color: var(--primarycolor);
			font-weight: var(--fw600);
		}
	}
	.maintitle{
		color: var(--blackcolortwo);
		font-size: var(--fs22);
		font-family: var(--fffira);
		line-height: 27px;
		max-width: 570px;
		margin:0 auto 40px;
		text-align: center;
	}
	.img_fit{
			display: block;
			margin: auto;
		}
	.pwd_title_block{
		padding: 50px 0;
		max-width: 360px;
		width: 100%;
		.subtitle{
			color: var(--graycolorthree);
			font-size: var(--fs16);
			font-family: var(--fffira);
			margin: 0 0 40px;
			line-height: 25px;
		}
		.field_group{
			position: relative;
			margin: 0 0 20px;
		}
		.icon{
			position: absolute;
		    top: 50%;
		    right: 20px;
		    @include transform(translateY(-50%));
		    cursor: pointer;
		}
		.txt_muted{
			color: var(--lightgraycolorseven);
			font-size: var(--fs13);
			font-family: var(--fffira);
			display: block;
			margin: 10px 0 0;
			&.txt_green_color{
				color: var(--greencolorone);
			}
		}
		.field_lbl{
			color: var(--blackcolorfive);
			font-size: var(--f14);
			font-family: var(--fffira);
			display: block;
			margin: 0 0 5px;
		}
		.field_input{
			max-width: 287px;
			height: 45px;
			border: 1px solid var(--lightgraycolornine);
			@include border-radius(4px);
			padding: 0 20px;
			color: var(--lightgraycolorfour);
			font-size: var(--f14);
			font-family: var(--fffira);
			margin: auto;
			display: block;
			&.field_width{
				padding: 0 50px 0 20px;
				max-width: 100% !important;
			}
		}
		.btn_block{
			margin: 40px 0 0;
			text-align: center;
			.btn{
				&:not(:last-child){
					margin: 0 10px 0 0;
				}
			}
			.link{
				margin: 0 30px 0 0;
				color: var(--primarycolor);
				font-size: var(--fs18);
				font-weight: var(--fw500);
				font-family: var(--fffira);
				display: block;		
			}
		}
	}
}