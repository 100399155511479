/*Button*/
.btn_outline{
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background-color: var(--whitecolor);
	color: var(--primarycolor);
	font-size: var(--fs18);
	font-family: var(--fffira);
	font-weight: 500;
	@include border-radius(6px);
	border: 1px solid var(--primarycolor);
	outline: none;
	height: 40px;
	line-height:40px;
	padding: 0 40px;
	text-align: center;
	@include transition(all,.3s,linear);
	position: relative;
	overflow: hidden;
	z-index: 1;
	display: flex;
    align-items: center;
    justify-content: center;
	.icon{
		margin:0 10px 0 0;
		display: inline-block;
	}
	&:after{
		content: "";
		position: absolute;
		inset: 0;
		@include transition(all,.3s,linear);
		background-color: var(--primarycolor);
		border: 1px solid var(--primarycolor);
		color: var(--whitecolor);
		@include transform(scaleX(0));
		z-index: -1;
	}
	&:hover{
		background-color: var(--primarycolor);
		color: var(--whitecolor);
		&:after{
			@include transform(scaleX(1));	
		}
	}
}

.btn_primary{
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background-color: var(--primarycolor);
	color: var(--whitecolor);
	font-size: var(--fs18);
	font-family: var(--fffira);
	font-weight: 500;
	@include border-radius(6px);
	border: 0;
	outline: none;
	height: 40px;
	line-height:40px;
	padding: 0 40px;
	text-align: center;
	@include transition(all,.3s,linear);
	display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px #00000029;
    -webkit-box-shadow: 0px 1px 4px #00000029;
	.icon{
		margin:0 10px 0 0;
		display: inline-block;
	}
	&.btn_completed{
		background-color: var(--lightbluecolortwo);
	}
	&:hover{
		color: var(--whitecolor);
	}
	&:disabled{
		filter: brightness(40%);
		cursor: no-drop
	}
}