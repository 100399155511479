/*BELOW CODE FOR LOGIN PAGE*/
.doctor-session-widget {
  margin-top: 2em
}

.txt_num_div {
  /*max-width: 102px;*/
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs25);
  font-family: var(--fffira);
  font-weight: var(--fw500);
  color: var(--darkbluecolorone);
  margin: 0 0 10px;
  @include border-radius(5px);
}

.txt_num_div_bkg {
  background-color: var(--lightbluecolorthirteen);
  padding: 0.5em 1em 0.5em 1em;
  @include border-radius(5px);
}

.txt_div {
  text-align: center;
  font-size: var(--fs18);
  font-family: var(--fffira);
  color: var(--blackcolorone);
}

.component-loading {
  filter: blur(1.5rem);
}