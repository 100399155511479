@media (min-width: 992px){
	.btn_hamburger{
		display: none;
	}
}

@media (min-width: 768px){
	.main_content {
		.btn_block {
			.btn{
				&:not(:last-child){
					margin: 0 10px 0 0;
				}
			}
		}
		.information_content {
			.fill_form_content {
				.btn{
					&:not(:last-child){
						margin: 0 10px 0 0;
					}
				}
			}
		}
	}
}

@media (max-width: 1199px){
	header {
		.inner_header {
			.listnav {
				align-items: flex-end;
				li {
					a {
						text-align: center;
						font-size: var(--fs13);
						display: flex;
						flex-direction: column;
						.txt-score{
							left: 0;
							top: 45px;
							font-size: var(--fs13);
						}
					}
				}
			}
		}
	}
	.account_content {
		.left_account_content {
			.custom_nav {
				.nav-item {
					.nav-link{
						font-size: var(--fs16);
						padding: 30px 10px !important;
					}
				}
			}
		}
		.title_block {
			.headtitle{
				font-size: var(--fs18) !important;
			}
			.btn_outline{
				width: auto !important;
			}
		}
		.table_account_content{

			.custom_table{	
				.btn_block{
					.btn{
						font-size: var(--fs14) !important;
						line-height: normal;
						white-space: nowrap;
						&:not(:last-child){
							margin: 0 10px 0 0;
						}
					}
				}
			}
		}
	}
	.main_content {
		.tab_wrapper {
				.tab_body_block {
					.appoinment_step_block{
						.steps{
			 	 			>ul{
			 	 				overflow-x: auto;
			 	 				display: flex;
			 	 				white-space: nowrap;
			 	 				>li{
			 	 					a{
			 	 						font-size: var(--fs14);
			 	 					}
			 	 				}
			 	 			}
			 	 		}
						ul {
							> li {
								a{
									font-size: var(--fs14);
								}
							}
						}
					}
				}
			}
		.inner_content {
			padding: 20px;
			.appointment_block {
				.appointment_head {
					.appointment_head_left {
						.list_app_status {
							li {
								a {
									.txt_num{
										font-size: var(--fs24);
									}
									.txt_word{
										font-size: var(--fs14);	
									}
								}
							}
						}
					}
					.appointment_head_right {
						.list_app_his_upcoming {
							li {
								a{
									height: 48px;
								}
							}
						}
					}
				}
			}
			.date_block {
				.calendar_block {
					.fc-header-toolbar {
						.fc-center {
							h2{
								font-size: var(--18);
							}
						}
					}
					.fc-view {
					.fc-head {
					.fc-head-container {
					.fc-widget-header {
					table {
					thead {
					tr {
						th{
							font-size: var(--fs14) !important;
						}
					}
					}
					}
					}
					}
					}
					.fc-body {
						.fc-row {
							a{
								font-size: var(--fs14) !important;		
							}
						}
					}
					}
					}
				.box_schedule {
					.subtitle{
						font-size:var(--fs20);
					}
				}
			}
		}
	}
}


@media (max-width: 991px){	
	header{
		padding: 10px;
		.inner_header {
			.logo{
				display: block;
				img{
					max-width: 100px;
				}
			}
			.btn_hamburger{
				display: block;
				background-color: var(--secondarycolor);
				width: 35px;
				height: 35px;
				@include border-radius(5px);
				border: 0;
				position: relative;
				margin: 0;
				&.active{
					.icon_bar{
						background-color: transparent;
						&:before{
							@include transform(rotate(45deg));
							transform-origin:1px 7px;
						}
						&:after{
							@include transform(rotate(-45deg));
							transform-origin: 3px -3px;
						}
					}
				}
				.icon_bar{
					width: 80%;
					height:3px;
					background-color: var(--whitecolor);
					display: inline-block;
					position: relative;
					top: -4px;
					@include transition(all .3s linear);
					&:before,&:after{
						left: 0;
						content: "";
						height: 3px;
						width: 100%;
						background-color: var(--whitecolor);
						position: absolute;
						@include transition(all .3s linear);
					}
					&:before{
						top: -10px;
					}
					&:after{
						top: 10px;
					}
				}
			}
			.listnav {
				li{
					&:not(:last-child){
						margin: 0 5px 0 0;
					}
					a{
						height: 30px;
						line-height: normal;
						padding: 0 5px;
					}
				}
			}
			.profile-block{
				.dropdown{
					.btn-profile{
						.txt{
							font-size: var(--fs13);
							margin: 0;
						}
					}
				}
				.btn-profile{
					.thumb{
						max-width: 50px;
						img{
							max-width: 50%;
						}
					}
				}
			}
		}
	}

	.gridcol {
		&.border_right{
			&:after{
				display: none;
			}
		}
	}

	.pwd_content {
		.title_block {
			margin: 0 0 20px;
			.headtitle{
				font-size: var(--fs24);
			}
		}
		.maintitle{
			font-size: var(--fs18);
		}
		.pwd_title_block {
			.subtitle{
				margin: 0 0 20px;
			}
		}
	}

	.main_content {
		padding: 10px;
		.section_title_block{
			margin: 0 0 10px;
			.head_title_block{
				font-size: var(--fs18);
			}
		}
		.patient_reg_block {
 	 			.box_patient_reg {
 	 				.field_group{
 	 					margin: 0 0 5px;
 	 					.btn{
 	 						padding: 0 10px;
 	 					}
 	 				}
 	 			}
 	 		}

		 .tab_wrapper {
		 	.box_search{
		 		top: 0;
		 	}
		 	 .tab_head_block {
		 	 	.customnav {
		 	 		.nav-item {
		 	 			.nav-link{
		 	 				padding: 10px;
		 	 				font-size: var(--fs12);
		 	 			}
		 	 		}
		 	 	}
		 	 }
		 	 .tab_body_block {
		 	 	.appoinment_step_block {
		 	 		.steps{
		 	 			>ul{
		 	 				overflow-x: auto;
		 	 				display: flex;
		 	 				white-space: nowrap;
		 	 				>li{
		 	 					a{
		 	 						font-size: var(--fs9);
		 	 						.number{
		 	 							width: 30px;
		 	 							height: 30px;
		 	 							font-size: var(--fs14);
		 	 						}
		 	 						.box{
		 	 							.line_divider_cs{
		 	 								position: relative;
		 	 								right: -25px;
		 	 							}
		 	 						}
		 	 					}
		 	 				}
		 	 			}
		 	 		}
		 	 		.patient_reg_block {
		 	 			.list_gender_radio {
		 	 				li {
		 	 					.field_lbl {
		 	 						.box_dot{
		 	 							height: 40px;
		 	 							line-height: 40px;
		 	 						}
		 	 						.txt{
		 	 							font-size: var(--fs14);
		 	 						}
		 	 					}
		 	 				}
		 	 			}
		 	 			.box_patient_reg {
		 	 				.field_group{
		 	 					margin: 0 0 5px;
		 	 				}
		 	 				.list_radio{
		 	 					 li{
		 	 					 	&:not(:last-child){
		 	 					 		margin: 0 0 10px !important;
		 	 					 	}
		 	 					 }
		 	 				}
		 	 			}
		 	 		}
		 	 		.content {
		 	 			padding: 10px;
		 	 			min-height: 60vh;
		 	 			.list_timeslot {
		 	 				li{
		 	 					&:not(:nth-child(4n+4)){
		 	 						padding: 0 8px 0 0;
		 	 					}
		 	 					width: calc(100% / 4);
		 	 					a{
		 	 						height: 50px;
		 	 						line-height: 50px;
		 	 					}
		 	 				}
		 	 			}
		 	 		}
		 	 	}
		 	 	.custom_tab_content {
		 	 		.healthinfo_content{
		 	 			padding: 10px;
		 	 		}
		 	 	}
		 	 }
		 }
		.info_content {
			.appointment_content {
				.appoint_body{
					padding: 20px;
					 .row {
					 	.px-5{
					 		padding: 0 !important;
					 	}
					 }
					 .list_type{
					 	margin: 0 0 10px;
					 }
				}
			}
		}
		.tabbed_inner_content {
			.custom_tab_content{
				padding: 10px;
				 .custom_tab_pane {
				 	padding: 10px;
				 	.tab_title_block {
				 		.headtitle{
				 			font-size: var(--fs16);
				 			margin: 0 0 10px;
				 		}
				 	}
				 }
			}
		}
		.inner_content{
			padding:10px;
			.date_block {
				.calendar_block{
					margin: 0 0 30px;
				}
				.title_block{
					.headtitle{
						margin: 0;
					}
				}
			}
			.appointment_block {	
				.custom_table{
					thead{
						tr{
							th{
								padding: 10px !important;
								.txt{
									font-size: var(--fs14) !important;
								}
							}
						}
					}
					tbody{
						tr{
							td{
								padding: 10px !important;
							}
						}
					}
				}
				.appointment_head {
					.appointment_head_left{
						margin: 0 ;
						padding: 10px;
						.list_app_status {
							li {
								a {
									.txt_num{
										font-size: var(--fs18);
										line-height: 18px;
										margin: 0;
									}
									.txt_word{
										font-size: var(--fs11);	
										margin: 0;
									}
								}
							}
						}
					}
					.appointment_head_right {
						.list_app_his_upcoming {
							li {
								a{
									font-size: var(--fs9);
									height: 25px;
									line-height: normal;
									text-align: center;
								}
							}
						}
					}
				}
			}
			.info_content {
				.information_content {
				 	.fill_form_content {
				 		.link_add_panel{
				 			margin: 0 0 30px;
				 			display: block;
				 		}
				 	}
				 }
				.appointment_content {
					.appoint_head {
						.txt_appoint_head{
							padding: 10px;
							font-size: var(--fs14);
						}
					}
				}
				.inner_info {
					.gridcol{
						&:not(:last-child){
							.box_info{
								margin: 0 0 30px;
							}
						}
					}
					
				}
				.feature_info {
					.box_feature {
						.thumb {
							height: 100px;
						}
					}
				}
				 .appointment_content {
				 	.appoint_body{
				 		padding: 20px;
				 	}
				 }
			}
		}
		.tabbed_inner_content {
			.custom_tab_content {
				&.custom_tab_pane_wo_spacing{
					padding: 0;
				}
				.custom_tab_pane {
					.custom_loc_clinic_nav{
						white-space: nowrap;
					    overflow-x: scroll;
					    flex-wrap: nowrap;
					    &::-webkit-scrollbar{
					    	display: none;
					    }
					}
					.list_content {
						.gridcol{
							margin: 0 0 30px;
						}
					}
					.custom_tab_pane {
						padding: 10px;
						.table_content {
							.custom_table {
								table-layout: auto;
								thead {
									tr {
										th{
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.information_content {
			.fill_form_content {
				.left_content{
					padding: 0;
					&.border_r{
						border-right: 0;
					}
				}
			}
		}
		.view_tab_content {
			.view_tab_nav{
				white-space: nowrap;
			    overflow-x: auto;
			    flex-wrap: nowrap;
			    margin: 20px 0 0;
			    order: 2;
			    &::-webkit-scrollbar{
			    	display: none;
			    }
			    li{
			    	a{
			    		font-size: var(--fs14);
			    	}
			    }
			}
			.btn_outline{
				width: auto;
			}
			.view_tab_pane {
				padding: 10px;
				.table_content {
					.custom_table {
						table-layout: auto;
						thead {
							tr {
								th{
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 767px){
	.btn_primary,.btn_outline{
		padding: 0 20px;
	}

	html,body{
		height: auto;
	}
	/*BELOW CODE FOR HEADER*/
	header {
		.inner_header{
			.profile-block {
				.dropdown {
					.btn-profile{
						flex-direction: column-reverse;
					}
				}
			}
		}
	}

	/*BELOW CODE FOR ACCOUNT*/
	.account_content {
		.fill_form_content {
			.list_bank_details {
				li {
					flex-direction: column;
					align-items: flex-start;
					.txt{
						width: 100%;
					}
				}
			}
		}
		.title_block{
			.headtitle{
				margin: 0 0 10px !important;
			}
		}
	}

	/*BELOW CODE FOR MODAL DESIGN ANYWAY*/
	.custom_modal{
		 .modal-content {
		 	.modal-header{
		 		.modal-title{
		 			font-size: var(--fs20);
		 		}
		 	}
		 	.modal-body {
		 		.upload_file_block {
		 			.lbl_file_upload{
		 				margin: 0 0 10px;
		 				width: 100%;
		 			}
		 			.txt_file{
		 				width: 100%;
		 			}
		 		}
		 		.num_issuedate_head {
		 			.num_block{
		 				align-items: flex-start;
		 				margin: 0 0 30px;
		 				flex-direction: column;
		 				.subtitle{
		 					font-size: var(--fs16);
		 					margin: 0 0 10px;
		 				}
		 			}
		 		}
		 		.custom_scrollbar {
		 			.modal_table_content {
		 				.custom_table {
		 					thead{
		 						tr{
		 							th{
		 								white-space: nowrap;
		 							}
		 						}
		 					}
		 					tbody {
		 						tr {
		 							td{
		 								white-space: nowrap;
		 							}
		 						}
		 					}
		 				}
		 			}
		 		}
		 		.fill_form_content {
		 			max-width: 100%;
		 			padding: 0 10px;
		 			.headingtitle{
		 				margin: 0 0 10px;
		 				font-size: var(--fs18);
		 			}
		 			.txt_title{
		 				font-size: var(--fs15);
		 			}
		 			.list_bank_details {
		 				li {
		 					.txt{
		 						width: 100%;
		 					}
		 				}
		 			}
		 			.btn_block {
		 				.btn{
		 					&:not(:last-child){
		 						margin: 0 0 10px;
		 					}
		 				}
		 			}
		 		}
		 		.info_modal_content{
		 			padding: 0;
		 			.btn_block{
		 				.btn{
		 					&:not(:last-child){
		 						margin: 0 0 10px;
		 					}
		 				}
		 			}
		 		}
		 		.btn_block {
		 			.btn{
		 				&:not(:last-child){
		 					margin: 0 0 10px;
		 				}
		 			}
		 		}
		 	}
		 }
	}


	/*BELOW CODE FOR MAIN CONTENT*/
	.pwd_content {
		padding: 20px;
		.pwd_title_block{
			max-width: 100%;
			.btn_block {
				.link{
					margin: 0 0 10px;
				}
				.btn{
					&:not(:last-child){
						margin: 0 0 10px;
					}
				}
				.btn_primary,.link{
					font-size: var(--fs16);
				}
			}
		}
	}
	.main_content {
		.tabbed_inner_content {
			.custom_nav{
			    white-space: nowrap;
			    overflow-x: auto;
			    flex-wrap: nowrap;
			    &::-webkit-scrollbar{
			    	display: none;
			    }
			    li{
			    	a{
			    		font-size: var(--fs14);
			    	}
			    }
			}
			.info_content {
				.appointment_content {
					.appoint_body{
						padding: 20px;
						.list_type {
							margin: 0 0 10px;
							li {
								.custom_select {
									.nice-select{
										width: 100%;
										.list{
											width: 100%;
										}
									}
								}
							}
						}
						.row{
							.px-5{
								padding: 0 !important;
							}
						}
					}
				}
			}
		}
		.information_content {
		 	.fill_form_content {
		 		.left_content,.right_content {
		 			.panel_block {
		 				.list_radio {
		 					flex-direction: column;
		 					li{
		 						width: 100%;
		 						&:not(:last-child){
		 							margin: 0 0 20px;
		 						}
		 					}
		 				}
		 			}
		 		}
		 	}
		 }
		.inner_content{
			padding: 20px;
			.title_block{
				.headtitle{
					font-size: var(--fs24);
					line-height: 24px;
					margin: 0 0 20px;
					letter-spacing: normal;
				}
				.desc{
					font-size: var(--fs16);
					letter-spacing: normal;
				}
			}
			.add_clinic_content {
				.subtitle{
					font-size: var(--fs16);
					margin: 0 0 20px;
				}
			}
			.info_content  {
				.subtitle{
					font-size: var(--fs16);
					margin: 0 0 20px;
					line-height: 20px;
				}
				.txt_desc{
					margin: -10px 0 40px;
				}
				.appointment_content {
					.appoint_body{
						.gridcol{
							padding:10px !important;
						}
						.list_type {
							li {
								.custom_select {
									.nice-select{
										width: 100%;
										.list{
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
				.custom_table {
					.field_head {
						.txt_field_head{
							padding: 0;
							font-size: var(--fs14);
							white-space: nowrap;
						}
					}
					.field_body {
						.field_box_body{
							padding: 5px;
							white-space: nowrap;
							.field_lbl_radio {
								.dot{
									width: 20px;
									height: 20px;
									&:after{
										height: 12px;
    									width: 6px;
									}
								}
							}
						}
					}
				}
				.box_info {
					.caption {
						.headtitle{
							font-size: var(--fs16);
							margin: 0 0 10px;
						}
					}
				}

			}
		}
		.view_tab_content {
			.view_tab_nav{
			    order: 2;
			}
		}
		.tabbed_inner_content {
			.custom_tab_content {
				.custom_tab_pane {
					.section_title {
						.subtitle{
							font-size: var(--fs16);
							margin: 0 0 10px;
						}
					}
				}
			}
		}
		.view_tab_content  {
			.view_tab_pane  {
				.section_title {
					.subtitle{
						font-size: var(--fs16);
						margin: 0 0 10px;
					}
				}
			}
		}
	}
	.info_content {
		.appointment_content {
			.appoint_body{
				padding: 20px;
				.list_type {
					margin: 0 0 10px;
					li {
						.custom_select {
							.nice-select{
								width: 100%;
								.list{
									width: 100%;
								}
							}
						}
					}
				}
				.row{
					.px-5{
						padding: 0 !important;
					}
				}
			}
		}
	}

	.body_content {
		padding: 30px 0;
	}

	/*BELOW CODE FOR LOGIN PAGE*/
	.login_content{
		padding: 20px;
		max-width: 300px;
		.title_block {
			margin: 0 0 20px;
			.headtitle{
				font-size: var(--fs24);
			}
		}
	}
	/*BELOW CODE FOR REGISTRATION PAGE*/
	.reg_content{
		padding: 20px;
		max-width: 300px;
		&.half_width{
			max-width:300px;
		}
		.title_block {
			margin: 0 0 20px;
			.headtitle{
				font-size: var(--fs24);
			}
			.maintitle{
				font-size: var(--fs24);
			}
			.desc{
				font-size: var(--fs16);
			}
		}
		.fill_form_content{
			.subtitle{
				font-size: var(--fs16);
			}
			 .field_group {
			 	.link {
			 		.txt{
			 			margin-left: 0;
			 		}
			 	}
			 }
		}
		.radio_block{
			.subtitle{
				margin: 0 0 10px;
			}
			.list_radio {
				li{
					&:not(:last-child){
						margin: 0 0 15px;
					}
				}
			}
		}
	}
}

@media print {

	.main_content {
		padding: 0 !important;
		margin: 0 !important;
	}
	.main_content .inner_content .appointment_block .appointment_body .custom_table tbody tr td .txt {
		font-size: 10px;
	}

	.main_content .inner_content .appointment_block .appointment_body .custom_table tbody tr td {
		padding: 0 !important;
	}
	.main_content .inner_content .appointment_block .appointment_body .custom_table thead tr th {
		font-size: 10px;
		padding: 0 !important;
	}
	.table>:not(caption)>*>* {
		padding: 0 !important;
	}
	.main_content .inner_content .subtitle_block {
		margin: 0 !important;
	}
	.mb-5 {
		margin-bottom: 5px !important;
	}
	.hwp,.link_printer,.td_last_col,.th_last_col,header {
		display: none !important;
	}
	.main_content .inner_content .appointment_block .appointment_head .appointment_head_right{
		margin:20px 0 0;
	}
	*{
		color-adjust: exact;
		-webkit-print-color-adjust: exact;
	}
}