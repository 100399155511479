/*BELOW CODE FOR MY ACCOUNT PAGE*/
.account_content{
	.title_block{
		margin: 0 0 40px;
		.headtitle{
			font-size: var(--fs22) !important;
			line-height: 17px !important;
			letter-spacing: 0.33px !important;
		}
	}
	.box_upload{
		padding: 0 20px 0 0;
		margin: 0 20px 0 0;
		.thumb_upload{
			width: 160px;
			height: 160px;
			@include border-radius(50%);
			border: 1px solid var(--graycolorone);
			margin: 0 auto 20px;
			cursor: pointer;
			padding:2px;
			position: relative;
			.img_upload_photo{
				width: 100%;
				height: 100%;
				@include border-radius(50%);
				object-fit: cover;
			}
			.field_upload_lbl{
			    position: absolute;
			    right: 0;
			    cursor: pointer;
			    bottom: 20px;
			    width: 36px;
			    height: 36px;
			    @include border-radius(50%);
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    background: var(--primarycolor);
			    .field_upload{
			    	display: none;
			    }
			}
		}
		.field_upload{
			text-align: center;
			margin: 0 0 37px;
			.custom_lbl{
				margin: 0 auto 12px;
				height: 40px;
				@include border-radius(6px);
				width: 200px;
				border: 1px solid var(--primarycolor);
				background-color: var(--whitecolor);
				color: var(--primarycolor);
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				@include transition(all,.3s,linear);
				position: relative;
				overflow: hidden;
				z-index: 1;
				&:hover{
					color: var(--whitecolor);
					.icon{
						img{
							filter: brightness(0) invert(1);
						}
					}
					&:after{
						@include transform(scaleX(1));	
					}
				}
				&:after{
					content: "";
					position: absolute;
					inset: 0;
					@include transition(all,.3s,linear);
					background-color: var(--primarycolor);
					border: 1px solid var(--primarycolor);
					color: var(--whitecolor);
					@include transform(scaleX(0));
					z-index: -1;
				}
				input{
					display: none;
				}
				.icon{
					display: inline-block;
					margin: 0 8px 0 0;
				}
				.txt{
					font-size: var(--fs16);
					font-weight: var(--fw500);
					font-family:  var(--fffira);
				}
			}
			.txt_upload{
				color: var(--blackcolorone);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				display: block;
			}
		}
		.box_message{
			margin: 0 0 40px;
			.txt_detail{
				color: var(--blackcolorone);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				display: block;
				margin: 0 0 10px;
			}
			.field_txtarea{
				border: 1px solid var(--lightgraycolorthree);
				@include border-radius(5px);
				height: 122px;
				width: 100%;
				overflow: hidden;
				resize: none;
				color: var(--blackcolorone);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				padding: 10px;
				line-height: 22px;
			}
		}
	}
	.table_account_content{
			.custom_table{
				text-align: center;
				thead{
					tr{
						th{
							vertical-align: middle;
							white-space: nowrap;
							padding: 10px;
							border-bottom: 1px solid var(--lightgraycolorthree);
							.txt{
								font-size: var(--fs14);
								font-family:  var(--fffira);
								color: var(--blackcolortwo);
								font-weight: var(--fw600);
								display: block;
							}
						}
					}
				}
				tbody{
					tr{
						td{
							vertical-align: middle;
							white-space: nowrap;
							padding: 10px;
							border-bottom: 1px solid var(--lightgraycolorthree);
							.txt{
								font-size: var(--fs14);
								font-family:  var(--fffira);
								color: var(--blackcolorfive);
								display: block;
							}
							.btn_block{
								margin: 0 !important;
							}
							.txt_num_session{
								border: 1px solid var(--primarycolor);
								padding: 0 20px;
								height: 35px;
								line-height: 35px;
								@include border-radius(5px);
								color: var(--primarycolor);
								font-size: var(--fs18);
								font-family:  var(--fffira);
								letter-spacing: 0.31px;
							}
							.txt_status{
								cursor: pointer;
								font-size: var(--fs13);
								font-family:  var(--fffira);
								display: inline-block;
								text-align: center;
								line-height: 33px;
								height: 33px;
								padding: 0 20px;
								@include border-radius(5px);
								&.txt_lightblue{
									background-color: var(--lightbluecolorsix);
									color: var(--darkbluecolorone);
								}
								&.txt_lightgreen{
									background-color: var(--lightgreencolorone);
									color: var(--lightgreencolortwo);
								}
							}
						}
					}
				}
			}
		}
	.fill_form_content{
		padding: 0 30px;
		.headingtitle{
			color: var(--blackcolortwo);
			font-size: var(--fs20);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			margin: 0 0 30px;
		}
		.txt_title{
			color: var(--blackcolorfive);
			font-size: var(--fs16);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			margin: 0 0 20px;
		}
		.maintitle{
			color: var(--blackcolortwo);
			font-size: var(--fs22);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			letter-spacing: 0.27px;
			margin: 0 0 20px;
		}
		&.half_width{
    		max-width: 420px;
		}
		.list_user_pwd{
			li{
				&:not(:last-child){
					margin: 0 0 30px;
				}
				a{
					color: var(--primarycolor);
					font-size: var(--fs16);
					font-weight: var(--fw500);
					font-family:  var(--fffira);
				}
			}
		}
		.list_bank_details{
			li{
			    display: flex;
				align-items: center;		
				&:not(:last-child){
					margin: 0 0 20px;
				}
				.txt{
					font-size: var(--fs16);
					font-family:  var(--fffira);
					color: var(--blackcolorfive);
					display: inline-block;
					width: 50%;
				}
			}
		}
		.field_group{
			margin: 0 0 20px;
			.field_lbl{
				color: var(--blackcolortwo);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				letter-spacing: 0.21px;
				display: block;
				margin: 0 0 9px;
			}
			.field_input{
				border: 1px solid var(--lightgraycolorone);
				height: 40px;
				@include border-radius(5px);
				font-family:  var(--fffira);
				font-weight: var(--fw500);
				font-size: var(--fs16);
				color: var(--blackcolorone);
				width: 100%;
				padding: 0 20px;
			}
		}	
	}
	.left_account_content{
		.custom_nav{
			border: 1px solid var(--lightgraycolorten);
			.nav-item{
				&:not(:last-child){
					border-bottom: 1px solid var(--lightgraycolorten);
				}
				&:first-child{
					a{
						&.active{
							@include border-radius(5px 0 0 0);
						}
					}
				}
				&:last-child{
					a{
						&.active{
							@include border-radius(0 0 0 5px);
						}
					}
				}
				.nav-link{
					padding: 30px 20px !important;
					&.active{
						border-left-color:var(--darkbluecolorone);
						background-color: var(--lightbluecolorfive);
						color: var(--darkbluecolorone);
						.icon_arrow {
							color: var(--darkbluecolorone);
						}
						.icon{
							img{
								filter: invert(24%) sepia(79%) saturate(1570%) hue-rotate(183deg) brightness(96%) contrast(103%);
								-webkit-filter: invert(24%) sepia(79%) saturate(1570%) hue-rotate(183deg) brightness(96%) contrast(103%);
							}
						}
					}
					.icon{
						display: inline-block;
						margin: 0 10px 0 0;
					}
					@include border-radius(0);
					cursor: pointer;
					border-left: 6px solid var(--whitecolor);
					color: var(--lightgraycolorseven);
					font-size: var(--fs18);
					font-family: var(--fffira);
					position: relative;
					.icon_arrow{
						position: absolute;
						top: 50%;
						right: 40px;
						@include transform(translateY(-50%));
					}
				}
			}
		}
	}
	.right_account_content{
		.custom_tab_content{
			.custom_tab_pane{
				.btn_block{
					margin: 0 0 40px;
					.btn_primary{
						font-size: var(--fs16);
					}
				}
				.edit_block{
					.headtitle{
						font-size: var(--fs22);
						font-weight: var(--fw500);
						color: var(--blackcolortwo);
						letter-spacing:.33px;
						margin: 0 0 40px;
					}
					.list_edit{
						li{
							&:not(:last-child){
								margin: 0 0 20px;
							}
							.detail_block{
								.txt{
									font-size: var(--fs14);
									color: var(--blackcolortwo);
									width: 50%;
								}
							}
						}
					}
				}
			}
		}
	}
}