/*BELOW CODE FOR REGISTRATION PAGE*/
.reg_content{
	background-color: var(--secondarycolor);
	@include border-radius(10px);
	margin: auto;
	padding: 50px;
	width: 100%;
	background-color: var(--whitecolor);
	&.half_width{
		max-width: 540px;
	}
	.title_block{
		margin: 0 0 30px;
		text-align: center;
		.headtitle{
			color: var(--blackcolor);
			font-size: var(--fs40);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 48px;
		}
		.subtitle{
			color: var(--blackcolorseven);
			font-size: var(--fs25);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 30px;
		}
		.maintitle{
			color: var(--blackcolor);
			font-size: var(--fs28);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 33px;
			margin: 0 0 30px;
		}
		.desc{
			color: var(--blackcolorone);
			font-size: var(--fs20);
			font-family:  var(--fffira);
			line-height: 24px;	
			&:not(:last-child){
				margin: 0 0 20px;
			}
		}
		.link{
			color: var(--primarycolor);
			font-weight: var(--fw600);
		}
	}
	.radio_block{
		margin: 0 0 30px;
		.subtitle{
			color: var(--blackcolortwo);
			font-family:  var(--fffira);
			font-size: var(--fs20);
			font-weight: var(--fw500);
			display: block;
			margin: 0 0 40px;
		}
		.list_radio{
			li{
				&:not(:last-child){
					margin: 0 0 30px;
				}
				label{
					cursor: pointer;
					display: flex;
					align-items: center;
					input{
						display: none;
						&:checked{
							~ .dot{
								background-color: var(--darkbluecolorone);
								border-color: var(--graycolorone);
							}
						}
					}
					.dot{
						width: 20px;
						height: 20px;
						display: inline-block;
						vertical-align: middle;
						margin: 0 15px 0 0;
						border: 1px solid var(--lightgraycolorone);
						@include border-radius(50%);
						@include transition(all,.3s,linear);
					}
					.txt{
						color: var(--blackcolorone);
						font-family:  var(--fffira);
						font-size: var(--fs16);
					}
				}
			}
		}
	}
	
	.fill_form_content{
		.gridcol{
			margin: 0 0 20px;
		}
		.field_group{
			position: relative;
			.field_lbl{
				color: var(--blackcolorone);
				font-family:  var(--fffira);
				font-size: var(--fs14);
				display: block;
				margin: 0 0 10px;
			}
			.txt_msg_success{
				color: var(--greencolorone);
				font-family:  var(--fffira);
				font-size: var(--fs14);
				display: block;
				margin: 10px 0 0;
			}
			.field_input{
				border: 1px solid var(--lightgraycolorone);
				height: 40px;
				@include border-radius(5px);
				font-family:  var(--fffira);
				font-weight: var(--fw500);
				font-size: var(--fs16);
				color: var(--blackcolorone);
				width: 100%;
				padding: 0 20px;
				&.field_input_icon{
					padding: 0 50px 0 20px;
				}
			}
			.icon_user{
				position: absolute;
			    top: 38px;
			    right: 10px;
			    width: 24px;
			    height: 24px;
			}
			.link{
				font-family:  var(--fffira);
				font-size: var(--fs14);
				font-weight: var(--fw600);
				&.txt_color_warning{
					color: var(--primarycolor);
				}
				.txt{
					color: var(--graycolortwo);
					display: inline-block;
					margin-left: 15px;
					font-weight: var(--fw400);
				}
			}
			.list_otp{
				display: flex;
				align-items: center;
				margin: 0 0 20px;
				li{
					width: calc(100% / 6);
					&:not(:last-child){
						margin: 0 10px 0 0;
					}
					.field_input{
						padding: 0;
						text-align: center;
					}
				}
			}
			.txt_otp{
				font-family:  var(--fffira);
				font-size: var(--fs14);
				color: var(--graycolortwo);
				display: block;
			}
		}
		.subtitle{
			color: var(--blackcolorone);
			font-family:  var(--fffira);
			font-size: var(--fs18);
			font-weight: var(--fw500);
			margin: 0 0 20px;

		}
	}
}