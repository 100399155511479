* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.wizard > .content > .body ul {
  list-style-type: none !important;
}

button {
  border: 0;
  background-color: transparent;

  &:focus {
    @include box-shadow(initial !important);
  }
}

input,
select,
textarea {
  transition: all 0.3s linear;
}

input:focus,
select:focus,
textarea:focus {
  &:not(.MuiInputBase-input) {
    border: 1px solid var(--darkbluecolorone) !important;
  }
}

.mCSB_scrollTools {
  background-color: var(--lightbluecoloreight);
  width: 7px;
  @include border-radius(0);
}

.mCSB_dragger_bar {
  width: 7px;
  background-color: var(--lightbluecoloreleven) !important;
  border-radius: 0 !important;
  opacity: 1 !important;
}

.mCSB_draggerRail {
  display: none;
}

.h_scrollbar::-webkit-scrollbar {
  height: 7px;
  background-color: var(--lightbluecoloreight);
}

.h_scrollbar::-webkit-scrollbar-thumb {
  height: 7px;
  background-color: var(--lightbluecoloreleven);
}

.gridcol {
  position: relative;

  &.border_right {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 10px;
      width: 1px;
      height: 100%;
      background-color: var(--lightgraycolorthree);
    }
  }
}

button,
input,
textarea,
input:focus,
textarea:focus {
  outline: none;
  box-shadow: initial;
  -webkit-box-shadow: initial;
}

a {
  text-decoration: none;
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--fffira);
  background-color: var(--lightbluecolorone);
}

header {
  background-color: var(--whitecolor);
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .inner_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      img {
        max-width: 100px;
      }
    }

    .listnav {
      display: flex;

      li {
        &:not(:last-child) {
          margin: 0 30px 0 0;
        }

        a {
          color: var(--darkbluecolorone);
          font-size: var(--fs16);
          @include transition(all, 0.3s, linear);
          position: relative;
          @include border-radius(4px);
          border: 1px solid var(--lightgraycolorthree);
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;

          &:hover,
          &.active {
            background-color: var(--primarycolor);
            color: var(--whitecolor);
            border-color: var(--primarycolor);
          }
        }
      }
    }

    .profile-block {
      .dropdown {
        .btn-profile {
          background-color: transparent;
          outline: none;
          box-shadow: initial;
          border: 0;
          cursor: pointer;
          display: flex;
          align-items: center;

          &:after {
            display: none;
          }

          .txt {
            color: var(--darkbluecolorone);
            font-size: var(--fs16);
            font-weight: var(--fw500);
            margin: 0 25px 0 0;
            display: inline-block;
          }
        }

        .dropdown-menu {
          box-shadow: 0px 2px 4px #00000029;
          -webkit-box-shadow: 0px 2px 4px var(--ligthgraycolorfive);
          border: 0;
          padding: 0;
          width: 200px;

          li {
            &:not(:last-child) {
              border-bottom: 1px solid var(--lightgraycolorsix);
            }

            a {
              padding: 10px;
              font-size: var(--fs14);
              font-family: var(--fffira);
              color: var(--darkbluecolorone);
            }
          }
        }
      }
    }
  }
}

/*BELOW CODE FOR ALL THE PAGES*/
.main_content {
  margin: 80px 0 0;
  padding: 10px 0;

  .loading_screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .section_doctors {
    ul {
      width: 100%;
      overflow-x: auto;
      li {
        &.active {
          background-color: var(--darkbluecolorone);
          color: var(--whitecolor);
        }
        &:hover {
          background-color: var(--darkbluecolorone);
          color: var(--whitecolor);
        }

        @media (max-width: 991px) {
          padding: 5px;
          width: 180px;
          min-width: 180px;
          font-size: 14px;
        }

        background-color: var(--lightgraycolorseventeen);
        color: var(--graycolortwo);
        padding: 10px;
        margin: 2px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 250px;
        min-width: 250px;

        div {
          text-align: center;
        }

        .session_timings {
          font-size: 12px;
          @media (max-width: 991px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .section_title_block {
    margin: 0 0 20px;

    .head_title_block {
      color: var(--blackcolorthree);
      font-size: var(--fs25);
      font-weight: var(--fw600);
      line-height: 30px;
    }
  }

  .big_headtitle {
    color: var(--blackcolorthree);
    font-size: var(--fs22);
    font-weight: var(--fw600);
    font-family: var(--fffira);
    margin: 0 0 30px;
  }

  .tab_wrapper {
    .toolbar_block {
      margin: 40px 20px;
    }

    .box_search {
      position: relative;
      overflow: hidden;
      width: 300px;
      top: -20px;

      &.top_0 {
        top: 0;
      }

      .icon_search {
        display: inline-block;
        margin: 0 10px;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        cursor: pointer;
      }

      .field_search {
        border: 1px solid var(--lightgraycolorthree);
        @include border-radius(5px);
        height: 45px;
        background-color: var(--whitecolor);
        color: var(--lightgraycolorseven);
        font-size: var(--fs14);
        padding: 0 30px 0 40px;
        outline: none;
        width: 100%;

        &:focus {
          overflow: hidden;
        }

        &:placeholder {
          color: var(--lightgraycolorseven);
        }
      }
    }

    .tab_head_block {
      .customnav {
        .nav-item {
          &:not(:last-child) {
            padding: 0 2px 0 0;
          }

          .nav-link {
            background-color: var(--lightbluecolorthree);
            padding: 10px 30px;
            color: var(--darkbluecolorone);
            font-size: var(--fs18);
            font-weight: var(--fw600);
            letter-spacing: 0.33px;
            @include border-radius(5px 5px 0 0);
            text-align: center;
            white-space: nowrap;

            &.active {
              background-color: var(--darkbluecolorone);
              color: var(--whitecolor);
            }
          }
        }
      }
    }

    .tab_body_block {
      .appoinment_step_block {
        margin: -30px 0px;

        ul {
          background-color: var(--lightorangecolorfive);
          display: flow-root;
          list-style-type: none !important;

          > li {
            position: relative;

            &:last-child {
              a {
                .box {
                  .line_divider_cs {
                    display: none;
                  }
                }
              }
            }

            &.done {
              a {
                .box {
                  .line_divider_cs {
                    background: var(--primarycolor) !important;
                  }
                }
              }
            }

            &.current {
              .number {
                background-color: var(--primarycolor);
                border-color: var(--primarycolor);
                color: var(--whitecolor);
              }

              a {
                color: var(--primarycolor);

                .box {
                  .line_divider_cs {
                    background: var(--primarycolor) !important;
                  }
                }
              }
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--lightgraycolorseven);
              font-size: var(--fs20);
              font-weight: var(--fw500);
              font-family: var(--fffira);

              .box {
                width: calc(100% - 52px);

                .txt {
                  width: 60%;
                }

                .line_divider_cs {
                  background: var(--lightgraycoloreighteen) !important;
                  height: 1px;
                  width: calc(100% - 60%);
                }
              }
            }
          }
        }

        .actions {
          width: 100%;
          border-top: 1px solid var(--lightgraycolortwo);
          padding: 20px 0;

          ul {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
              &:after {
                display: none;
              }

              &:nth-child(1) {
                a,
                span {
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                  background-color: var(--whitecolor);
                  color: var(--primarycolor);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: 500;
                  @include border-radius(6px);
                  border: 1px solid var(--primarycolor);
                  outline: none;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 40px;
                  text-align: center;
                  @include transition(all, 0.3s, linear);
                  position: relative;
                  overflow: hidden;
                  z-index: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    margin: 0 10px 0 0;
                    display: inline-block;
                  }

                  &:after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    @include transition(all, 0.3s, linear);
                    background-color: var(--primarycolor);
                    border: 1px solid var(--primarycolor);
                    color: var(--whitecolor);
                    @include transform(scaleX(0));
                    z-index: -1;
                  }

                  &:hover {
                    background-color: var(--primarycolor);
                    color: var(--whitecolor);

                    &:after {
                      @include transform(scaleX(1));
                    }
                  }
                }
              }

              &:nth-child(2) {
                a,
                span {
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                  background-color: var(--primarycolor);
                  color: var(--whitecolor);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: 500;
                  @include border-radius(6px);
                  border: 0;
                  outline: none;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 40px;
                  text-align: center;
                  @include transition(all, 0.3s, linear);
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    margin: 0 10px 0 0;
                    display: inline-block;
                  }

                  &.btn_completed {
                    background-color: var(--lightbluecolortwo);
                  }

                  &:hover {
                    color: var(--whitecolor);
                  }
                }
              }

              &:nth-child(3) {
                a,
                span {
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                  background-color: var(--primarycolor);
                  color: var(--whitecolor);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: 500;
                  @include border-radius(6px);
                  border: 0;
                  outline: none;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 40px;
                  text-align: center;
                  @include transition(all, 0.3s, linear);
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    margin: 0 10px 0 0;
                    display: inline-block;
                  }

                  &.btn_completed {
                    background-color: var(--lightbluecolortwo);
                  }

                  &:hover {
                    color: var(--whitecolor);
                  }
                }
              }
            }
          }
        }

        .actions-clone {
          width: 100%;
          padding: 20px 0;

          ul {
            background-color: transparent;
            display: flex;
            align-items: center;
            width: 100%;

            li {
              margin-left: 1em;
              &:after {
                display: none;
              }

              &:nth-child(1) {
                a,
                span {
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                  background-color: var(--whitecolor);
                  color: var(--primarycolor);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: 500;
                  @include border-radius(6px);
                  border: 1px solid var(--primarycolor);
                  outline: none;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 40px;
                  text-align: center;
                  @include transition(all, 0.3s, linear);
                  position: relative;
                  overflow: hidden;
                  z-index: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    margin: 0 10px 0 0;
                    display: inline-block;
                  }

                  &:after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    @include transition(all, 0.3s, linear);
                    background-color: var(--primarycolor);
                    border: 1px solid var(--primarycolor);
                    color: var(--whitecolor);
                    @include transform(scaleX(0));
                    z-index: -1;
                  }

                  &:hover {
                    background-color: var(--primarycolor);
                    color: var(--whitecolor);

                    &:after {
                      @include transform(scaleX(1));
                    }
                  }
                }
              }

              &:nth-child(2) {
                a,
                span {
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                  background-color: var(--primarycolor);
                  color: var(--whitecolor);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: 500;
                  @include border-radius(6px);
                  border: 0;
                  outline: none;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 40px;
                  text-align: center;
                  @include transition(all, 0.3s, linear);
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    margin: 0 10px 0 0;
                    display: inline-block;
                  }

                  &.btn_completed {
                    background-color: var(--lightbluecolortwo);
                  }

                  &:hover {
                    color: var(--whitecolor);
                  }
                }
              }
            }
          }
        }

        .wizard {
          > .steps {
            .disabled {
              &:after {
                background-color: var(--lightgraycoloreighteen) !important;
              }

              a {
                background-color: transparent;
              }
            }

            .current {
              &:after {
                background-color: var(--primarycolor) !important;
              }

              a {
                background: transparent;
                color: var(--primarycolor);

                &:active {
                  background: transparent;
                  color: var(--lightgraycolorseven);
                }
              }
            }

            .done {
              &:after {
                background-color: var(--primarycolor) !important;
              }

              a {
                color: var(--primarycolor);
                background-color: transparent;

                .number {
                  background-color: var(--primarycolor);
                  color: var(--whitecolor);
                }
              }
            }
          }
        }

        .number {
          border: 1px solid var(--lightgraycolorthree);
          background-color: var(--lightorangecolorfive);
          width: 42px;
          height: 42px;
          @include border-radius(50%);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--lightgraycolorseven);
          font-size: var(--fs20);
          font-family: var(--fffira);
          font-weight: var(--fw500);
          margin: 0 10px 0 0;
        }

        .content {
          margin: 0;
          background-color: var(--whitecolor);
          padding: 30px;
          //min-height: 100vh;

          .body {
            width: 100%;
            height: 100%;
            padding: 0;
          }

          .calendar_block {
            .fc-header-toolbar {
              position: relative;
              background-color: var(--lightbluecolorfourteen);
              border: 1px solid var(--lightbluecoloreleven);
              @include border-radius(5px 5px 0 0);
              margin: 0;

              .fc-toolbar-chunk {
                .fc-button-primary {
                  background-color: transparent;
                  border-color: transparent;
                  color: var(--blackcolorone);
                  flex: inherit;
                }

                .fc-toolbar-title {
                  color: var(--blackcolorone);
                  font-size: var(--fs25);
                  font-family: var(--fffira);
                }
              }
            }

            .fc-view-container {
              .fc-view {
                .fc-head {
                  background-color: var(--lightorangecolortwo);

                  .fc-head-container {
                    .fc-widget-header {
                      table {
                        thead {
                          tr {
                            th {
                              font-weight: var(--fw400);
                              font-size: var(--fs23);
                              font-family: var(--fffira);
                              color: var(--blackcolorone);
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .fc-body {
                  .fc-scroller {
                    height: auto !important;
                    overflow: hidden !important;
                  }

                  .fc-row {
                    min-height: auto !important;
                    height: 75px !important;

                    .fc-disabled-day {
                      background: transparent !important;
                    }

                    a {
                      color: var(--blackcolor);
                      font-size: var(--fs24);
                      font-family: var(--fffira);
                      text-decoration: none;
                      display: block;
                      line-height: 60px !important;
                    }

                    .fc-today {
                      background: var(--lightbluecoloreleven) !important;
                    }
                  }
                }
              }
            }
          }

          .list_timeslot {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            background-color: transparent;

            li {
              &:after {
                display: none;
              }

              &:not(:nth-child(6n + 6)) {
                padding: 0 8px 0 0;
              }

              &.active {
                a {
                  background-color: var(--darkbluecolorone);

                  .txt {
                    color: var(--whitecolor);
                  }
                }
              }

              margin: 0 0 20px;
              width: calc(100% / 6);

              a {
                text-align: center;
                @include border-radius(6px);
                border: 1px solid var(--lightgraycolorthree);
                height: 72px;
                line-height: 72px;
                display: block;
                padding: 0 10px;
                @include transition(all, 0.3s, linear);

                &.disabled {
                  opacity: 0.3;
                  pointer-events: none;
                  cursor: default;
                }

                &.active,
                &:hover {
                  border-color: var(--lightgreencolor2);
                  background-color: var(--lightgreencolor1);

                  .txt {
                    color: var(--blackcoloreight);
                  }
                }

                &.current {
                  border-color: var(--lightorangecoloreight);
                  background-color: var(--lightorangecolorseven);

                  .txt {
                    color: var(--blackcoloreight);
                  }
                }

                .txt {
                  color: rgba(2, 2, 2, 0.42);
                  font-size: var(--fs14);
                  font-family: var(--ffpoppins);
                }
              }
            }
          }
        }

        .patient_reg_block {
          .txt_desc {
            font-size: var(--fs16);
            letter-spacing: 0.24px;
            font-weight: var(--fw400);
            line-height: 24px;
            margin: 0 0 30px;
            color: var(--blackcolorthree);
          }

          .list_otp {
            display: flex;
            align-items: center;
            margin: 0 0 30px;

            .field_otp {
              margin: 0 10px 0 0;
              width: 40px;
              height: 45px;
              border: 1px solid var(--lightgraycolorthree);
              @include border-radius(4px);
              color: var(--blackcolorone);
              font-size: var(--fs14);
              font-weight: var(--fw500);
              font-family: var(--ffpoppins);
              text-align: center;
            }

            .link_success {
              &.active {
                display: block !important;
              }
            }
          }

          .left_patient_reg,
          .right_patient_reg {
            position: relative;
            padding: 0 20px;
          }

          .v_right_divider {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 1px;
              background-color: var(--lightgraycolorthree);
            }
          }

          .v_left_divider {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 1px;
              background-color: var(--lightgraycolorthree);
            }
          }

          .list_gender_radio {
            display: flex;
            background-color: transparent;

            li {
              width: calc(100% / 3);

              &:after {
                display: none;
              }

              .field_lbl {
                width: 100%;
                padding: 0 5px;
                position: relative;
                cursor: pointer;

                input {
                  display: none;

                  &:checked {
                    ~ .box_dot {
                      background-color: var(--darkbluecolorone);
                      border-color: var(--darkbluecolorone);
                    }
                  }

                  &:checked {
                    ~ .txt {
                      color: var(--whitecolor);
                    }
                  }
                }

                .box_dot {
                  background-color: var(--whitecolor);
                  border: 1px solid var(--lightgraycolorthree);
                  height: 50px;
                  @include border-radius(4px);
                  width: 100%;
                  display: block;
                  @include transition(all, 0.3s, linear);
                }

                .txt {
                  color: var(--lightgraycolorseven);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: var(--fw500);
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  @include transform(translate(-50%, -50%));
                }
              }
            }
          }

          .gridcol {
            .box_patient_reg {
              margin: 0 0 20px;
            }
          }

          .box_patient_reg {
            .txt_field {
              font-size: var(--fs18);
              font-family: var(--fffira);
              color: var(--blackcolorthree);
              line-height: 22px;
              display: block;
            }

            .field_title {
              min-width: 130px;
            }

            .list_radio {
              margin: 0 0 30px;
              background-color: transparent;

              li {
                width: 100% !important;

                &:after {
                  display: none;
                }

                &:not(:last-child) {
                  margin: 0 0 30px !important;
                }

                label {
                  .txt {
                    font-weight: var(--fw500);
                  }
                }
              }
            }

            .maintitle_block {
              font-size: var(--fs20);
              letter-spacing: 0.3px;
              font-weight: var(--fw600);
              line-height: 24px;
              margin: 0 0 30px;
              color: var(--blackcolorthree);
            }

            .list_radio {
              display: flex;
              align-items: center;

              li {
                width: calc(100% / 3);

                &:not(:last-child) {
                  margin: 0 30px 0 0;
                }

                label {
                  cursor: pointer;
                  display: flex;
                  align-items: center;

                  input {
                    display: none;

                    &:checked {
                      ~ .dot {
                        background-color: var(--darkbluecolorone);
                        border-color: var(--graycolorone);
                      }
                    }
                  }

                  .dot {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 15px 0 0;
                    border: 1px solid var(--lightgraycolorone);
                    @include border-radius(50%);
                    @include transition(all, 0.3s, linear);
                  }

                  .txt {
                    color: var(--blackcolorone);
                    font-family: var(--fffira);
                    font-size: var(--fs16);
                  }
                }
              }
            }

            .field_group {
              margin: 0 0 20px;

              .field_lbl {
                color: var(--blackcolorone);
                font-size: var(--fs16);
                font-family: var(--fffira);
                display: block;
                margin: 0;
                letter-spacing: 0.24px;
              }

              .field_input {
                border: 1px solid var(--lightgraycolorone);
                height: 40px;
                @include border-radius(5px);
                font-family: var(--fffira);
                font-size: var(--fs14);
                color: var(--blackcolorone);
                width: 100%;
                padding: 0 15px;
              }

              .custom_select {
                .nice-select {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .custom_tab_content {
        background-color: var(--whitecolor);
        padding: 30px 0;

        .healthinfo_content {
          padding: 30px;

          .box_healthinfo {
            margin: 0 0 20px;

            .txt_fieldlbl {
              display: block;
              color: var(--blackcolortwo);
              font-size: var(--fs16);
              font-family: var(--fffira);
              margin: 0 0 25px;
            }

            .txt_small {
              color: var(--blackcolortwo);
              font-size: var(--fs16);
              font-family: var(--fffira);
              margin: 0 40px 0 0;
              display: inline-block;
            }

            .field_input {
              border: 1px solid var(--lightgraycolorone);
              height: 40px;
              @include border-radius(5px);
              font-family: var(--fffira);
              font-size: var(--fs14);
              color: var(--blackcolorone);
              width: 50%;
              padding: 0 15px;
            }

            .list_radio {
              display: flex;
              align-items: center;

              li {
                &:not(:last-child) {
                  margin: 0 50px 0 0;
                }

                label {
                  cursor: pointer;
                  display: flex;
                  align-items: center;

                  input {
                    display: none;

                    &:checked {
                      ~ .dot {
                        background-color: var(--darkbluecolorone);
                        border-color: var(--graycolorone);
                      }
                    }
                  }

                  .dot {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 15px 0 0;
                    border: 1px solid var(--lightgraycolorone);
                    @include border-radius(50%);
                    @include transition(all, 0.3s, linear);
                  }

                  .txt {
                    color: var(--blackcolorone);
                    font-family: var(--fffira);
                    font-size: var(--fs14);
                  }
                }
              }
            }

            .list_chk_radio {
              display: flex;
              align-items: center;

              &:not(:last-child) {
                margin: 0 0 20px;
              }

              li {
                padding: 0 20px 0 0;
                width: calc(100% / 3);
                margin: 0 0 30px;

                label {
                  cursor: pointer;
                  margin: 0;
                  display: flex;
                  align-items: flex-start;

                  input {
                    display: none;

                    &:checked {
                      ~ .dot {
                        background-color: var(--darkbluecolorone);
                        border-color: var(--darkbluecolorone);

                        &:after {
                          content: "";
                          display: inline-block;
                          @include transform(rotate(45deg));
                          height: 12px;
                          width: 7px;
                          border-bottom: 2px solid var(--whitecolor);
                          border-right: 2px solid var(--whitecolor);
                        }
                      }
                    }
                  }

                  .dot {
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    @include border-radius(3px);
                    background-color: var(--whitecolor);
                    border: 1px solid var(--lightgraycolortwo);
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 10px 0 0;
                    line-height: 13px;
                    float: left;
                    text-align: center;
                  }

                  .txt {
                    font-size: var(--fs14);
                    font-family: var(--fffira);
                    color: var(--blackcolortwo);
                    white-space: nowrap;
                    width: calc(100% - 28px);
                    display: inline-block;
                  }
                }
              }
            }
          }

          .field_group {
            margin: 0 0 20px;

            .field_lbl {
              color: var(--blackcolorone);
              font-size: var(--fs16);
              font-family: var(--fffira);
              display: block;
              margin: 0;
              letter-spacing: 0.24px;
            }

            .field_input {
              border: 1px solid var(--lightgraycolorone);
              height: 40px;
              @include border-radius(5px);
              font-family: var(--fffira);
              font-size: var(--fs14);
              color: var(--blackcolorone);
              width: 100%;
              padding: 0 15px;
            }

            .txt_field {
              color: var(--blackcolorthree);
              font-size: var(--fs18);
              font-family: var(--fffira);
            }

            .custom_select {
              .nice-select {
                width: 100%;
              }
            }
          }
        }

        .custom_table {
          width: 100%;

          thead {
            tr {
              th {
                white-space: nowrap;
                padding: 10px;
                border-bottom: 1px solid rgba(66, 66, 66, 0.2);

                .txt {
                  color: var(--blackcolortwo);
                  font-size: var(--fs16);
                  font-family: var(--fffira);
                }
              }
            }
          }

          tbody {
            tr {
              td {
                white-space: nowrap;
                padding: 10px;
                border-bottom: 1px solid rgba(66, 66, 66, 0.2);

                .txt {
                  color: var(--blackcolorone);
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                }

                .link_notifylab {
                  background-color: var(--lightorangecolorfour);
                  @include border-radius(5px);
                  height: 33px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                  font-weight: var(--fw500);
                  text-align: center;
                  color: var(--primarycolor);
                  padding: 0 10px;
                }

                .dot_checkbox {
                  cursor: pointer;
                  position: relative;

                  input {
                    display: none;

                    &:checked {
                      ~ .dot {
                        background-color: var(--lightgreencolortwo);
                        border-color: var(--lightgreencolortwo);
                      }
                    }
                  }

                  .dot {
                    @include transition(all, 0.3s, linear);
                    border: 1px solid var(--lightgraycolorseventeen);
                    @include border-radius(50%);
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:after {
                      content: "";
                      @include transform(rotate(40deg));
                      height: 14px;
                      width: 6px;
                      border-bottom: 2px solid var(--whitecolor);
                      border-right: 2px solid var(--whitecolor);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .inner_content {
    padding: 50px;

    &.reduce_spacing {
      padding: 20px;
    }

    background-color: var(--whitecolor);

    &.half_width_content {
      max-width: 540px;
      margin: auto;
      display: block;
    }

    .subtitle_block {
      font-size: var(--fs22);
      font-weight: var(--fw500);
      color: var(--blackcolorthree);
      letter-spacing: 0.33px;
      margin: 0 0 30px;
    }

    .view_block {
      .custom_table {
        thead {
          tr {
            th {
              border-bottom: 1px solid rgba(66, 66, 66, 0.2);
              padding: 20px;
              vertical-align: middle;

              .txt {
                color: var(--blackcolortwo);
                font-size: var(--fs16);
                font-family: var(--fffira);
              }
            }
          }
        }

        tbody {
          tr {
            &:last-child {
              td {
                border-bottom: 0;
              }
            }

            td {
              border-bottom: 1px solid rgba(66, 66, 66, 0.2);
              padding: 20px;
              vertical-align: middle;

              .view_status_block {
                .link_view {
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                  background-color: var(--whitecolor);
                  color: var(--primarycolor);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                  font-weight: 500;
                  @include border-radius(6px);
                  border: 1px solid var(--primarycolor);
                  outline: none;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 40px;
                  text-align: center;
                  @include transition(all, 0.3s, linear);
                  position: relative;
                  overflow: hidden;
                  z-index: 1;
                  display: inline-block;

                  .icon {
                    margin: 0 10px 0 0;
                    display: inline-block;
                  }

                  &:after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    @include transition(all, 0.3s, linear);
                    background-color: var(--primarycolor);
                    border: 1px solid var(--primarycolor);
                    color: var(--whitecolor);
                    @include transform(scaleX(0));
                    z-index: -1;
                  }

                  &:hover {
                    background-color: var(--primarycolor);
                    color: var(--whitecolor);

                    &:after {
                      @include transform(scaleX(1));
                    }
                  }
                }

                .checkbox_block {
                  label {
                    input {
                      display: none;
                    }

                    .dot {
                      width: 24px;
                      height: 24px;
                      @include border-radius(50%);
                      background-color: var(--lightgreencolortwo);
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      &:after {
                        content: "";
                        @include transform(rotate(45deg));
                        height: 12px;
                        width: 6px;
                        border-bottom: 2px solid var(--whitecolor);
                        border-right: 2px solid var(--whitecolor);
                      }
                    }
                  }
                }
              }

              .thumb_caption_block {
                .thumb {
                  width: 60px;
                  margin: 0 40px 0 0;

                  .img_fit {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }

              .txt {
                color: var(--blackcolortwo);
                font-size: var(--fs16);
                font-family: var(--fffira);
              }
            }
          }
        }
      }
    }

    .event_block {
      .txt_field {
        color: var(--blackcolorone);
        font-size: var(--fs16);
        font-family: var(--fffira);
      }

      .field_input {
        border: 1px solid var(--lightgraycolorone);
        height: 40px;
        @include border-radius(5px);
        font-family: var(--fffira);
        font-size: var(--fs14);
        color: var(--blackcolorone);
        width: 100%;
        padding: 0 15px;
      }

      .link_printer {
        color: var(--darkbluecolorone);
        font-size: var(--fs14);
        font-family: var(--fffira);
        font-weight: var(--fw400);

        .fa {
          margin-right: 5px;
        }
      }

      .custom_table {
        thead {
          tr {
            th {
              border-top: 1px solid rgba(66, 66, 66, 0.2);
              border-bottom: 1px solid rgba(66, 66, 66, 0.2);
              padding: 20px;

              .txt {
                color: var(--blackcolortwo);
                font-size: var(--fs16);
                font-family: var(--fffira);
              }
            }
          }
        }

        tbody {
          tr {
            td {
              border-bottom: 1px solid rgba(66, 66, 66, 0.2);
              padding: 20px;

              .txt {
                color: var(--blackcolortwo);
                font-size: var(--fs16);
                font-family: var(--fffira);
              }
            }
          }
        }
      }
    }

    .appointment_block {
      .appointment_head {
        padding: 20px;

        .appointment_head_left {
          background-color: var(--lightorangecolorthree);
          @include border-radius(8px);
          padding: 20px;

          .list_app_status {
            display: flex;
            align-items: center;
            text-align: center;

            li {
              width: calc(100% / 5);
              position: relative;
              display: flex;
              justify-content: center;

              &:not(:last-child) {
                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0px;
                  width: 1px;
                  height: 100%;
                  background-color: var(--primarycolor);
                }
              }

              a {
                .txt_num {
                  color: var(--primarycolor);
                  font-size: var(--fs30);
                  font-weight: var(--fw500);
                  font-family: var(--fffira);
                  display: block;
                  margin: 0 0 8px;
                }

                .txt_word {
                  color: var(--blackcolorone);
                  font-size: var(--fs18);
                  font-family: var(--fffira);
                }
              }
            }
          }
        }

        .appointment_head_right {
          .list_app_his_upcoming {
            li {
              &:not(:last-child) {
                margin: 0 0 10px;
              }

              a {
                @include border-radius(5px);
                background-color: var(--lightorangecolorsix);
                color: var(--primarycolor);
                font-size: var(--fs18);
                font-family: var(--fffira);
                font-weight: var(--fw500);
                height: 55px;
                display: flex;
                align-items: center;
                padding: 0 3px;
                justify-content: center;
              }
            }
          }
        }
      }

      .printer_block {
        padding: 20px;

        .link_printer {
          color: var(--darkbluecolorone);
          font-size: var(--fs14);
          font-family: var(--fffira);
          font-weight: var(--fw400);
        }
      }

      .appointment_body {
        .custom_table {
          thead {
            tr {
              th {
                white-space: nowrap;
                border-top: 1px solid var(--lightgraycolorthree);
                border-bottom: 1px solid var(--lightgraycolorthree);
                padding: 20px;
                vertical-align: middle;

                .txt {
                  font-size: var(--fs16);
                  font-family: var(--fffira);
                  // font-weight: var(--fw400);
                  color: var(--blackcolortwo);
                }
              }
            }
          }

          tbody {
            tr {
              &.highlighted_appointment {
                background-color: var(--lightbluecolortwelve);
              }
              td {
                white-space: nowrap;
                border-top: 1px solid var(--lightgraycolorthree);
                border-bottom: 1px solid var(--lightgraycolorthree);
                padding: 10px 20px;
                vertical-align: middle;

                .txt {
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                  font-weight: var(--fw400);
                  color: var(--blackcolor);

                  &.txt_cursor {
                    cursor: pointer;
                  }
                }

                .arrived_readiness_status_block {
                  .link_arrive {
                    white-space: nowrap;
                    border: 1px solid var(--lightgraycolorthree);
                    @include border-radius(5px);
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--lightgraycolorfour);
                    background-color: var(--whitecolor);
                    font-size: var(--fs13);
                    font-family: var(--fffira);
                    padding: 0 30px;
                    margin: 0 15px 0 0;
                    display: flex;

                    &.link_arrive_success {
                      border-color: var(--lightgreencolortwo);
                      color: var(--lightgreencolortwo);
                      background-color: var(--lightgreencolorone);
                    }
                  }

                  .link_arrived {
                    white-space: nowrap;
                    border: 1px solid var(--greencolorone);
                    @include border-radius(5px);
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--greencolorone);
                    background-color: var(--whitecolor);
                    font-size: var(--fs13);
                    font-family: var(--fffira);
                    padding: 0 30px;
                    margin: 0 15px 0 0;
                    display: flex;

                    &.link_arrive_success {
                      border-color: var(--lightgreencolortwo);
                      color: var(--lightgreencolortwo);
                      background-color: var(--lightgreencolorone);
                    }
                  }

                  .link_arrived_disabled {
                    white-space: nowrap;
                    border: 1px solid var(--lightgreencolortwo);
                    @include border-radius(5px);
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--lightgraycolorfour);
                    background-color: var(--whitecolor);
                    font-size: var(--fs13);
                    font-family: var(--fffira);
                    padding: 0 30px;
                    margin: 0 15px 0 0;
                    display: flex;

                    &.link_arrive_success {
                      border-color: var(--lightgreencolortwo);
                      color: var(--lightgreencolortwo);
                      background-color: var(--lightgreencolorone);
                    }
                  }

                  .link_vitals {
                    white-space: nowrap;
                    border: 1px solid var(--lightgraycolorthree);
                    @include border-radius(5px);
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--lightgraycolorfour);
                    background-color: var(--whitecolor);
                    font-size: var(--fs13);
                    font-family: var(--fffira);
                    padding: 0 30px;
                    margin: 0 15px 0 0;
                    display: flex;

                    &.link_vitals_success {
                      border-color: var(--greencolorone);
                      color: var(--greencolorone);
                      background-color: var(--whitecolor);
                    }
                  }

                  .link_readiness {
                    white-space: nowrap;
                    border: 1px solid var(--primarycolor);
                    @include border-radius(5px);
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--primarycolor);
                    background-color: var(--whitecolor);
                    font-size: var(--fs12);
                    font-family: var(--fffira);
                    padding: 0 30px;
                    background-color: var(--whitecolor);
                    display: flex;
                    margin: 0 15px 0 0;
                  }

                  .status_block {
                    display: flex;

                    label {
                      margin: 0;
                      cursor: pointer;

                      input {
                        display: none;

                        &:checked ~ .dot {
                          border-color: var(--lightgreencolortwo);
                          background-color: var(--lightgreencolortwo);
                        }
                      }

                      .dot {
                        display: flex;
                        width: 18px;
                        height: 18px;
                        @include border-radius(50%);
                        border: 1px solid var(--lightgraycolorthree);
                        background-color: var(--whitecolor);
                      }
                    }
                  }
                }

                .link_cancel {
                  white-space: nowrap;
                  border: 1px solid var(--primarycolor);
                  @include border-radius(5px);
                  height: 30px;
                  line-height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--primarycolor);
                  font-size: var(--fs12);
                  font-family: var(--fffira);
                  padding: 0 25px;
                  background-color: var(--whitecolor);
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    .patient_reg_block {
      .txt_desc {
        font-size: var(--fs16);
        letter-spacing: 0.24px;
        font-weight: var(--fw400);
        line-height: 24px;
        margin: 0 0 30px;
        color: var(--blackcolorthree);
      }

      .list_otp {
        display: flex;
        align-items: center;
        margin: 0 0 30px;

        .field_otp {
          margin: 0 10px 0 0;
          width: 40px;
          height: 45px;
          border: 1px solid var(--lightgraycolorthree);
          @include border-radius(4px);
          color: var(--blackcolorone);
          font-size: var(--fs14);
          font-weight: var(--fw500);
          font-family: var(--ffpoppins);
          text-align: center;
        }

        .link_success {
          &.active {
            display: block !important;
          }
        }
      }

      .left_patient_reg,
      .right_patient_reg {
        position: relative;
        padding: 0 20px;
      }

      .v_right_divider {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background-color: var(--lightgraycolorthree);
        }
      }

      .v_left_divider {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background-color: var(--lightgraycolorthree);
        }
      }

      .list_gender_radio {
        display: flex;

        li {
          width: calc(100% / 3);

          .field_lbl {
            width: 100%;
            padding: 0 5px;
            position: relative;
            cursor: pointer;

            input {
              display: none;

              &:checked {
                ~ .box_dot {
                  background-color: var(--darkbluecolorone);
                  border-color: var(--darkbluecolorone);
                }
              }

              &:checked {
                ~ .txt {
                  color: var(--whitecolor);
                }
              }
            }

            .box_dot {
              background-color: var(--whitecolor);
              border: 1px solid var(--lightgraycolorthree);
              height: 50px;
              @include border-radius(4px);
              width: 100%;
              display: block;
              @include transition(all, 0.3s, linear);
            }

            .txt {
              color: var(--lightgraycolorseven);
              font-size: var(--fs18);
              font-family: var(--fffira);
              font-weight: var(--fw500);
              position: absolute;
              top: 50%;
              left: 50%;
              @include transform(translate(-50%, -50%));
            }
          }
        }
      }

      .gridcol {
        .box_patient_reg {
          margin: 0 0 20px;
        }
      }

      .box_patient_reg {
        .txt_field {
          font-size: var(--fs18);
          font-family: var(--fffira);
          color: var(--blackcolorthree);
          line-height: 22px;
          display: block;
        }

        .list_radio {
          margin: 0 0 30px;

          li {
            width: 100% !important;

            &:not(:last-child) {
              margin: 0 0 30px !important;
            }

            label {
              .txt {
                font-weight: var(--fw500);
              }
            }
          }
        }

        .maintitle_block {
          font-size: var(--fs20);
          letter-spacing: 0.3px;
          font-weight: var(--fw600);
          line-height: 24px;
          margin: 0 0 30px;
          color: var(--blackcolorthree);
        }

        .list_radio {
          display: flex;
          align-items: center;

          li {
            width: calc(100% / 3);

            &:not(:last-child) {
              margin: 0 30px 0 0;
            }

            label {
              cursor: pointer;
              display: flex;
              align-items: center;

              input {
                display: none;

                &:checked {
                  ~ .dot {
                    background-color: var(--darkbluecolorone);
                    border-color: var(--graycolorone);
                  }
                }
              }

              .dot {
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: middle;
                margin: 0 15px 0 0;
                border: 1px solid var(--lightgraycolorone);
                @include border-radius(50%);
                @include transition(all, 0.3s, linear);
              }

              .txt {
                color: var(--blackcolorone);
                font-family: var(--fffira);
                font-size: var(--fs16);
              }
            }
          }
        }

        .field_group {
          margin: 0 0 20px;

          .field_lbl {
            color: var(--blackcolorone);
            font-size: var(--fs16);
            font-family: var(--fffira);
            display: block;
            margin: 0;
            letter-spacing: 0.24px;
          }

          .field_input {
            border: 1px solid var(--lightgraycolorone);
            height: 40px;
            @include border-radius(5px);
            font-family: var(--fffira);
            font-size: var(--fs14);
            color: var(--blackcolorone);
            width: 100%;
            padding: 0 15px;
          }

          .custom_select {
            .nice-select {
              width: 100%;
            }
          }
        }
      }
    }

    .date_block {
      .calendar_block {
        .fc-header-toolbar {
          position: relative;
          background-color: var(--lightbluecolorfourteen);
          border: 1px solid var(--lightbluecoloreleven);
          @include border-radius(5px 5px 0 0);
          margin: 0;

          .fc-left {
            position: relative;
            z-index: 9;
            width: 100%;

            .fc-button-group {
              justify-content: space-between;
              width: 100%;

              .fc-button-primary {
                background-color: transparent;
                border-color: transparent;
                color: var(--blackcolorone);
                flex: inherit;
              }
            }
          }

          .fc-right {
            display: none;
          }

          .fc-center {
            position: absolute;
            top: 50%;
            left: 0;
            text-align: center;
            width: 100%;
            @include transform(translateY(-50%));

            h2 {
              color: var(--blackcolorone);
              font-size: var(--fs25);
              font-family: var(--fffira);
            }
          }
        }

        .fc-view-container {
          .fc-view {
            .fc-head {
              background-color: var(--lightorangecolortwo);

              .fc-head-container {
                .fc-widget-header {
                  table {
                    thead {
                      tr {
                        th {
                          font-weight: var(--fw400);
                          font-size: var(--fs23);
                          font-family: var(--fffira);
                          color: var(--blackcolorone);
                        }
                      }
                    }
                  }
                }
              }
            }

            .fc-body {
              .fc-scroller {
                height: auto !important;
                overflow: hidden !important;
              }

              .fc-row {
                min-height: auto !important;
                height: 75px !important;

                .fc-disabled-day {
                  background: transparent !important;
                }

                a {
                  color: var(--blackcolor);
                  font-size: var(--fs24);
                  font-family: var(--fffira);
                  text-decoration: none;
                  display: block;
                  line-height: 70px !important;
                }

                .fc-today {
                  background: var(--lightbluecoloreleven) !important;
                }
              }
            }
          }
        }
      }

      .title_block {
        margin: 0 0 30px;

        .headtitle {
          font-size: var(--fs25);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          color: var(--blackcolorseven);
          margin: 0 0 20px;
        }

        .txt {
          font-size: var(--fs20);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          color: var(--blackcolortwo);
        }
      }

      .box_schedule {
        height: 370px;
        overflow: hidden;
        padding: 30px 10px;
        @include border-radius(10px);
        background-color: var(--lightbluecolortwelve);
        @include box-shadow(0px 0px 7px #0000001a);
        display: flex;
        flex-direction: column;

        .subtitle {
          font-size: var(--fs25);
          font-family: var(--fffira);
          font-weight: var(--fw500);
          color: var(--darkbluecolorone);
          margin: 0 0 25px;

          .icon {
            display: inline-block;
            margin: 0 10px 0 0;
          }
        }

        .list_events {
          display: flex;
          justify-content: space-around;
          margin: 0 0 30px;

          li {
            justify-content: space-between;
            display: flex;
            align-items: center;

            .txt_num {
              width: 102px;
              height: 66px;
              background-color: var(--lightbluecolorthirteen);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: var(--fs25);
              font-family: var(--fffira);
              font-weight: var(--fw500);
              color: var(--darkbluecolorone);
              margin: 0 0 10px;
              @include border-radius(5px);
            }

            .txt {
              font-size: var(--fs18);
              font-family: var(--fffira);
              color: var(--blackcolorone);
            }
          }
        }

        .txt_time {
          font-size: var(--fs18);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          color: var(--graycolorthree);
          display: block;
          margin: 0 0 30px;
          text-align: center;
        }

        .list_date_toolbar {
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            &:not(:last-child) {
              margin: 0 20px 0 0;
            }

            a {
              position: relative;
              background-color: var(--whitecolor);
              color: var(--lightgraycolorfour);
              display: flex;
              align-items: center;
              justify-content: center;
              justify-content: center;
              height: 50px;
              width: 50px;
              @include border-radius(5px);

              &:hover {
                .tooltip_block {
                  opacity: 1;
                  visibility: visible;
                }
              }

              .tooltip_block {
                position: absolute;
                background-color: var(--whitecolor);
                text-align: center;
                padding: 10px;
                @include box-shadow(0px 3px 6px #00000029);
                opacity: 0;
                visibility: visible;
                top: -60px;
                left: 0;
                z-index: 1;
                width: max-content;

                &:after {
                  content: "";
                  border-top: 15px solid #fff;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  width: 0;
                  height: 0;
                  position: absolute;
                  left: 0px;
                  bottom: -14px;
                }

                .txt {
                  font-size: var(--fs14);
                  color: var(--graycolorthree);
                }
              }
            }
          }
        }
        @media (max-width: 1346px) {
          height: 420px;
          overflow: auto;
        }
        @media (max-width: 995px) {
          height: 480px;
          overflow: auto;
        }

        @media (max-width: 768px) {
          height: fit-content;
          overflow: auto;
        }
      }
    }

    .schedule_content {
      background-color: var(--whitecolor);
      height: 100vh;
      /*form styles*/
      #msform {
        text-align: center;
        position: relative;
      }

      #msform fieldset {
        width: 100%;

        /*stacking fieldsets above each other*/
        position: relative;
      }

      /*Hide all except first fieldset*/
      #msform fieldset:not(:first-of-type) {
        display: none;
      }

      /*headings*/
      .fs-title {
        font-size: 18px;
        text-transform: uppercase;
        color: #2c3e50;
        margin-bottom: 10px;
        letter-spacing: 2px;
        font-weight: bold;
      }

      .fs-subtitle {
        font-size: var(--fs16);
        font-weight: var(--fw500);
        color: var(--blackcolortwo);
        margin: 0 0 30px;
      }

      .tab_content .btn_outline,
      .tab_content .btn_primary {
        width: auto;
      }

      /*progressbar*/
      #progressbar {
        margin: 30px 0;
        /*CSS counters to number the steps*/
        counter-reset: step;
      }

      #progressbar li {
        list-style-type: none;
        color: var(--blackcolortwo);
        font-size: var(--fs14);
        font-weight: var(--fw500);
        width: 33.33%;
        float: left;
        position: relative;
        letter-spacing: 1px;
      }

      #progressbar li .txt_step {
        position: relative;
        top: -60px;
      }

      #progressbar li:before {
        content: counter(step);
        counter-increment: step;
        width: 24px;
        height: 24px;
        line-height: 26px;
        display: block;
        font-size: 12px;
        color: var(--whitecolor);
        background: var(--lightgraycolorone);
        border-radius: 25px;
        margin: 0 auto 10px auto;
        z-index: 10;
        position: relative;
      }

      /*progressbar connectors*/
      #progressbar li:after {
        content: "";
        width: 90%;
        height: 2px;
        background: var(--lightgraycolorone);
        position: absolute;
        left: -45%;
        top: 9px;
        z-index: 9;
      }

      #progressbar li:first-child:after {
        /*connector not needed before the first step*/
        content: none;
      }

      /*marking active/completed steps green*/
      /*The number of the step and the connector before it = green*/
      #progressbar li.active:before,
      #progressbar li.active:after {
        background: var(--lightbluecolortwo);
        color: var(--whitecolor);
      }

      .tab_content {
        position: relative;
        text-align: left;
      }

      .tab_content .subtitle {
        font-size: var(--fs16);
        font-weight: var(--fw500);
        color: var(--blackcolortwo);
        margin: 0 0 30px;
      }

      .tab_content .list_chk li:not(:last-child) {
        margin: 0 0 10px;
      }

      .tab_content .list_chk li label {
        cursor: pointer;
      }

      .tab_content .list_chk li label input {
        display: none;
      }

      .tab_content .list_chk li label .dot {
        margin: 0 10px 0 0;
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background-color: var(--whitecolor);
        border: 1px solid var(--lightgraycolorthree);
        line-height: 20px;
        text-align: center;
        position: relative;
      }

      .tab_content .list_chk li label .dot:after {
        content: "";
        display: inline-block;
        transform: rotate(45deg);
        height: 15px;
        width: 8px;
        border-bottom: 2px solid var(--whitecolor);
        border-right: 2px solid var(--whitecolor);
        opacity: 0;
      }

      .tab_content .list_chk li label .txt {
        color: var(--lightgraycolorfour);
        font-size: var(--fs14);
        font-family: var(--fffira);
      }

      .tab_content .list_chk li label input:checked ~ .dot {
        background-color: var(--darkbluecolorone);
      }

      .tab_content .list_chk li label input:checked ~ .dot:after {
        opacity: 1;
      }

      .tab_content .list_chk li label input:checked ~ .txt {
        color: var(--blackcolorone);
      }

      .txt {
        font-size: var(--fs16);
        font-family: var(--fffira);
        letter-spacing: 0.24px;
        display: inline-block;
        color: var(--blackcolorone);
      }

      .txt_gray_color {
        color: var(--lightgraycoloreight);
        font-size: var(--fs14);
        font-weight: var(--fw500);
      }

      .field_input {
        border: 1px solid var(--lightgraycolorthirteen);
        height: 40px;
        @include border-radius(5px);
        font-family: var(--fffira);
        font-weight: var(--fw500);
        font-size: var(--fs16);
        color: var(--blackcolorone);
        width: 100%;
        padding: 0 20px;
      }

      .custom_select {
        .nice-select {
          width: 100%;
          font-weight: var(--fw500);
          color: var(--blackcolorone);

          .list {
            width: 100%;

            li {
              padding: 0 10px;
            }
          }
        }
      }

      .custom_datepicker {
        .field_date {
          cursor: pointer;
        }
      }
    }

    .title_block {
      .headtitle {
        color: var(--blackcolor);
        font-size: var(--fs40);
        font-weight: var(--fw500);
        font-family: var(--fffira);
        line-height: 48px;
        letter-spacing: 0.8px;
        margin: 0 0 30px;
      }

      .desc {
        color: var(--blackcolorone);
        font-size: var(--fs20);
        font-family: var(--fffira);
        line-height: 24px;
        letter-spacing: 0.4px;

        &:not(:last-child) {
          margin: 0 0 20px;
        }
      }
    }

    .feedback_block {
      overflow: hidden;

      .nav_month_yr_block {
        .custom_feedback_nav {
          margin: 0 0 30px;

          li {
            a {
              color: var(--lightgraycolorseven);
              font-size: var(--fs22);
              font-family: var(--fffira);
              font-weight: var(--fw500);
              position: relative;
              display: block;
              padding: 10px 20px;

              &.active {
                color: var(--primarycolor);

                &:after {
                  content: "";
                  height: 3px;
                  width: 100%;
                  background-color: var(--darkbluecolorone);
                  display: block;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                }
              }
            }
          }
        }

        .month_year_block {
          .list_details {
            display: flex;

            > li {
              &:not(:last-child) {
                margin: 0 10px 0 0;
              }

              .nice-select {
                width: 120px;
                padding: 0 40px 0 20px;
                font-size: var(--fs15);
                font-weight: var(--fw600);
                font-family: var(--fffira);
              }
            }
          }
        }
      }

      .feedback_tab_content {
        .feedback_tab_pane {
          .feedback_table {
            .custom_table {
              border: 1px solid var(--lightgraycolorthree);

              thead {
                tr {
                  th {
                    vertical-align: middle;
                    border-bottom: 1px solid var(--lightgraycolorthree);
                    padding: 20px;

                    &:not(:last-child) {
                      border-right: 1px solid var(--lightgraycolorthree);
                    }

                    .txt {
                      color: var(--blackcolorone);
                      font-size: var(--fs13);
                      font-family: var(--fffira);
                      font-weight: var(--600);
                      display: block;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    vertical-align: middle;

                    &:not(:last-child) {
                      border-right: 1px solid var(--lightgraycolorthree);
                    }

                    &.border_r_wo {
                      border-right: 0;
                    }

                    padding: 20px;
                    border-bottom: 1px solid var(--lightgraycolorthree);

                    &.td_spacing {
                      padding: 80px 20px;
                    }

                    .txt {
                      color: var(--blackcolorone);
                      font-size: var(--fs14);
                      font-family: var(--fffira);
                      display: block;
                    }

                    .list_feedback {
                      li {
                        &:not(:last-child) {
                          margin: 0 0 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .box_feedback {
            border: 1px solid var(--lightgraycolorfourteen);
            @include border-radius(10px);
            padding: 30px;
            margin: 0 0 40px;

            .subtitle {
              color: var(--blackcolortwo);
              font-size: var(--fs16);
              font-family: var(--fffira);
              font-weight: var(--fw500);
              margin: 0 0 10px;
              line-height: 20px;
            }

            .desc {
              color: var(--blackcolortwo);
              font-size: var(--fs14);
              font-family: var(--fffira);
              line-height: 22px;
            }
          }
        }
      }
    }

    .temp_block {
      overflow: hidden;

      .temp_nav {
        margin: 0;

        li {
          width: calc(100% / 5);

          &:not(:last-child) {
            padding: 0 2px 0 0;
          }

          a {
            background-color: var(--lightbluecolorthree);
            padding: 10px 30px;
            color: var(--lightgraycolorseven);
            font-size: var(--fs18);
            font-weight: var(--fw500);
            letter-spacing: 0.33px;
            @include border-radius(5px 5px 0 0);
            text-align: center;
            white-space: nowrap;

            &.active {
              background-color: var(--darkbluecolorone);
              color: var(--whitecolor);
            }
          }
        }
      }

      .temp_tabcontent {
        .temp_tabpane {
          background-color: var(--whitecolor);
          padding: 40px 20px;

          .section_title {
            .headtitle {
              color: var(--blackcolorfour);
              font-size: var(--fs22);
              font-weight: var(--fw600);
              font-family: var(--fffira);
            }
          }

          .title_block {
            .headtitle {
              color: var(--blackcolortwo);
              font-size: var(--fs30);
              font-family: var(--fffira);
              margin: 0 0 15px;
            }

            .subtitle {
              color: var(--blackcolortwo);
              font-size: var(--fs18);
              font-family: var(--fffira);
              margin: 0 0 20px;
            }

            .btn_outline {
              width: auto;
            }
          }

          .custom_pathimg_nav {
            margin: 0 0 20px;

            li {
              a {
                color: var(--lightgraycolorseven);
                font-size: var(--fs20);
                font-family: var(--fffira);
                font-weight: var(--fw600);
                padding: 10px 20px;
                position: relative;

                &.active {
                  background-color: transparent;
                  color: var(--darkbluecolorone);

                  &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background-color: var(--primarycolor);
                  }
                }
              }
            }
          }

          .template_table {
            max-width: 632px;
            width: 100%;

            .custom_table {
              border: 1px solid var(--lightgraycolorthree);

              thead {
                tr {
                  th {
                    vertical-align: middle;
                    border-bottom: 1px solid var(--lightgraycolorthree);
                    padding: 20px;

                    .txt {
                      color: var(--blackcolorone);
                      font-size: var(--fs13);
                      font-family: var(--fffira);
                      font-weight: var(--600);
                      display: block;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    vertical-align: middle;

                    &.border_r_wo {
                      border-right: 0;
                    }

                    padding: 20px;
                    border-bottom: 1px solid var(--lightgraycolorthree);

                    .txt {
                      color: var(--blackcolorone);
                      font-size: var(--fs14);
                      font-family: var(--fffira);
                      display: block;
                    }

                    .list_toolbar {
                      display: flex;

                      li {
                        &:not(:last-child) {
                          margin: 0 10px 0 0;
                        }

                        a {
                          width: 34px;
                          height: 34px;
                          border: 1px solid var(--lightgraycolorthree);
                          @include border-radius(3px);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*TABBED ONE*/
  .tabbed_inner_content {
    .custom_nav {
      margin: 0 0 20px;

      li {
        a {
          color: var(--lightgraycolorseven);
          font-size: var(--fs22);
          font-family: var(--fffira);
          font-weight: var(--fw500);
          padding: 10px 20px;
          position: relative;

          &.active {
            background-color: transparent;
            color: var(--darkbluecolorone);

            &:after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 4px;
              background-color: var(--primarycolor);
            }
          }
        }
      }
    }

    .custom_tab_content {
      background-color: var(--whitecolor);
      @include border-radius(4px);

      .custom_tab_pane {
        padding: 50px 30px;

        &.custom_tab_pane_wo_spacing {
          padding: 0;
        }

        .custom_loc_clinic_nav {
          border-bottom: 1px solid var(--lightgraycolorone);
          margin: 0 0 40px;

          .nav-item {
            .nav-link {
              padding: 20px 30px;
              @include border-radius(0);
              color: var(--lightgraycolorfour);
              font-size: var(--fs18);
              font-weight: var(--fw500);
              font-family: var(--fffira);
              text-transform: uppercase;

              &.active {
                background-color: var(--lightbluecolorfour);
                color: var(--blackcolortwo);
              }
            }
          }
        }

        .custom_table {
          thead tr th,
          tbody tr td {
            white-space: nowrap;
          }

          thead {
            tr {
              th {
                padding: 10px;
                vertical-align: middle;
                border-bottom: 1px solid var(--lightgraycolorone);

                &:nth-child(1) {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 20%;
                }

                &:nth-child(3) {
                  width: 30%;
                }

                &:nth-child(4) {
                  width: 30%;
                }

                &:nth-child(5) {
                  width: 10%;
                  text-align: center;
                }

                .txt {
                  color: var(--blackcolortwo);
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                  font-weight: var(--fw600);
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 10px;
                vertical-align: middle;
                border-bottom: 1px solid var(--lightgraycolorone);

                .txt {
                  color: var(--blackcolortwo);
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                  font-weight: var(--fw500);

                  &.txt_gray_color {
                    color: var(--lightgraycoloreight);
                  }
                }

                .list_toolbar {
                  display: flex;

                  li {
                    &:not(:last-child) {
                      margin: 0 10px 0 0;
                    }

                    a {
                      width: 34px;
                      height: 34px;
                      border: 1px solid var(--lightgraycolorthree);
                      @include border-radius(3px);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }

        .tab_title_block {
          .headtitle {
            font-size: var(--fs22);
            color: var(--blackcolorone);
            font-family: var(--fffira);
            font-weight: var(--fw500);
          }

          .btn_primary {
            font-size: var(--fs16);

            .icon {
              margin: 0 10px 0 0;
              display: inline-block;
            }
          }
        }

        .section_title {
          .subtitle {
            font-size: var(--fs22);
            color: var(--blackcolorone);
            font-family: var(--fffira);
            font-weight: var(--fw600);
            letter-spacing: 0.33px;
          }

          .btn_primary {
            font-size: var(--fs16);
          }
        }

        .list_content {
          .box_list {
            margin: 0 0 10px;
            border: 1px solid var(--lightgraycolorthree);
            padding: 20px;
            @include border-radius(5px);

            .loc_block {
              display: flex;
              align-items: center;
              margin: 0 0 40px;

              .icon {
                margin: 0 8px 0 0;
              }

              .txt_loc {
                font-size: var(--fs14);
                font-family: var(--fffira);
                color: var(--blackcolorone);
              }
            }

            .list_toolbar {
              li {
                &:not(:last-child) {
                  margin: 0 10px 0 0;
                }

                a {
                  border: 1px solid var(--lightgraycolorthree);
                  @include border-radius(3px);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 34px;
                  height: 34px;
                }
              }
            }
          }

          .btn_block {
            .btn_primary {
              font-size: var(--fs14);
            }
          }
        }
      }
    }
  }

  .information_content {
    .fill_form_content {
      .left_content {
        padding: 0 30px 0 0;

        &.border_r {
          border-right: 1px solid var(--lightgraycolorthree);
        }

        .panel_block {
          margin: 0 0 20px;

          &.with_opacity {
            opacity: 0.5;
          }

          .maintitle {
            color: var(--blackcolorfour);
            font-size: var(--fs18);
            letter-spacing: 0.27px;
            font-family: var(--fffira);
            margin: 0 0 20px;
          }

          .list_radio {
            display: flex;
            align-items: center;

            li {
              width: calc(100% / 3);

              &:not(:last-child) {
                margin: 0 30px 0 0;
              }

              label {
                cursor: pointer;
                display: flex;
                align-items: center;

                input {
                  display: none;

                  &:checked {
                    ~ .dot {
                      background-color: var(--darkbluecolorone);
                      border-color: var(--graycolorone);
                    }
                  }
                }

                .dot {
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 15px 0 0;
                  border: 1px solid var(--lightgraycolorone);
                  @include border-radius(50%);
                  @include transition(all, 0.3s, linear);
                }

                .txt {
                  color: var(--blackcolorone);
                  font-family: var(--fffira);
                  font-size: var(--fs16);
                }
              }
            }
          }

          .field_group {
            margin: 0 0 20px;

            .field_lbl {
              color: var(--blackcolorone);
              font-size: var(--fs14);
              font-family: var(--fffira);
              display: block;
              margin: 0 0 10px;
            }

            .field_input {
              border: 1px solid var(--lightgraycolorone);
              height: 40px;
              @include border-radius(5px);
              font-family: var(--fffira);
              font-weight: var(--fw500);
              font-size: var(--fs16);
              color: var(--blackcolorone);
              width: 100%;
              padding: 0 15px;
            }
          }
        }
      }

      .right_content {
        @extend .left_content;
      }
    }
  }

  .view_tab_content {
    .view_tab_nav {
      margin: 0;

      li {
        margin: 0 2px;

        a {
          background-color: var(--lightbluecolorthree);
          padding: 10px 30px;
          color: var(--lightgraycolorseven);
          font-size: var(--fs22);
          font-weight: var(--fw500);
          letter-spacing: 0.33px;
          @include border-radius(5px 5px 0 0);

          &.active {
            background-color: var(--darkbluecolorone);
            color: var(--whitecolor);
          }
        }
      }
    }

    .view_tab_pane {
      background-color: var(--whitecolor);
      padding: 40px 20px;

      .table_content {
        .btn_primary {
          font-size: var(--fs16);
        }

        .custom_table {
          table-layout: fixed;

          thead tr th,
          tbody tr td {
            white-space: nowrap;
          }

          thead {
            tr {
              th {
                padding: 10px;
                vertical-align: middle;
                border-bottom: 1px solid var(--lightgraycolorone);

                &:nth-child(1) {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 20%;
                }

                &:nth-child(3) {
                  width: 30%;
                }

                &:nth-child(4) {
                  width: 30%;
                }

                &:nth-child(5) {
                  width: 10%;
                  text-align: center;
                }

                .txt {
                  color: var(--blackcolortwo);
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                  font-weight: var(--fw600);
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 10px;
                vertical-align: middle;
                border-bottom: 1px solid var(--lightgraycolorone);

                .txt {
                  color: var(--blackcolortwo);
                  font-size: var(--fs14);
                  font-family: var(--fffira);
                  font-weight: var(--fw500);

                  &.txt_gray_color {
                    color: var(--lightgraycoloreight);
                  }
                }

                .list_toolbar {
                  display: flex;

                  li {
                    &:not(:last-child) {
                      margin: 0 10px 0 0;
                    }

                    a {
                      width: 34px;
                      height: 34px;
                      border: 1px solid var(--lightgraycolorthree);
                      @include border-radius(3px);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .section_title {
        .subtitle {
          font-size: var(--fs22);
          color: var(--blackcolorone);
          font-family: var(--fffira);
          font-weight: var(--fw600);
          letter-spacing: 0.33px;
        }

        .btn_primary {
          font-size: var(--fs16);
        }
      }

      .btn_block {
        &.btn_block_save_active {
          display: flex !important;
        }
      }
    }
  }

  /*BELOW CODE FOR CONSULTATION DURATION PAGE*/
  .info_content {
    .subtitle {
      color: var(--blackcolorthree);
      font-size: var(--fs20);
      font-family: var(--fffira);
      line-height: 24px;
      letter-spacing: 0.4px;
      margin: 0 0 40px;
    }

    .txt_desc {
      color: var(--lightgraycolorfour);
      font-size: var(--fs16);
      font-family: var(--fffira);
      line-height: 17px;
      margin: -30px 0 40px;
    }

    .inner_info {
      margin: 0 0 50px;

      .box_info {
        border: 1px solid var(--lightgraycolorthree);
        @include border-radius(5px);
        padding: 20px;

        &.br_0 {
          @include border-radius(0);
        }

        .thumb {
          margin: 0 auto 20px;
          text-align: center;
          height: 200px;

          .img_fit {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .caption {
          .headtitle {
            background-color: var(--darkbluecolorone);
            color: var(--whitecolor);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: var(--fs18);
            font-family: var(--fffira);
            font-weight: var(--fw500);
            @include border-radius(5px);
            margin: 0 0 30px;
            letter-spacing: 0.36px;
            line-height: 22px;
            height: 60px;
          }

          .desc {
            line-height: 25px;
            font-size: var(--fs14);
            font-family: var(--fffira);
            font-weight: var(--fw500);
            color: var(--blackcolorone);
            letter-spacing: 0.28px;
          }
        }
      }
    }

    .feature_info {
      margin: 0 0 50px;

      .box_feature {
        border: 1px solid var(--lightgraycolorthree);
        padding: 20px;
        text-align: center;

        .thumb {
          height: 200px;

          .img_fit {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .custom_table {
      border: 1px solid var(--lightgraycolorthree);
      border-left: 0;

      .field_head {
        background-color: var(--darkbluecolorone);
        padding: 20px;
        text-align: center;

        tr {
          th {
            vertical-align: middle;

            &:first-child {
              border-right: 1px solid var(--lightgraycolorthree);
            }

            border-bottom: 0;
          }
        }

        .txt_field_head {
          color: var(--whitecolor);
          font-size: var(--fs16);
          font-family: var(--fffira);
          letter-spacing: 0.32px;
          font-weight: var(--fw500);
          text-align: center;
          padding: 20px;
          display: block;
        }
      }

      .field_body {
        tr {
          td {
            vertical-align: middle;

            &:first-child {
              border-right: 1px solid var(--lightgraycolorthree);
            }
          }
        }

        .field_box_body {
          padding: 30px;

          .field_lbl_radio {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            input {
              display: none;

              &:checked {
                ~ .dot {
                  background-color: var(--darkbluecolorone);
                  border-color: var(--darkbluecolorone);
                }
              }
            }

            .dot {
              border: 1px solid var(--lightgraycolorone);
              width: 30px;
              height: 30px;
              @include border-radius(50%);
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              @include transition(all, 0.3s, linear);

              &:after {
                content: "";
                display: inline-block;
                @include transform(rotate(45deg));
                height: 16px;
                width: 8px;
                border-bottom: 2px solid var(--whitecolor);
                border-right: 2px solid var(--whitecolor);
                @include transition(all, 0.3s, linear);
              }
            }
          }
        }

        .gridcol {
          border-bottom: 1px solid var(--lightgraycolorthree);

          &:nth-child(4n + 1),
          &:nth-child(4n + 4) {
            border-right: 1px solid var(--lightgraycolorthree);
          }
        }
      }
    }

    .appointment_content {
      border: 1px solid var(--lightgraycolorthree);

      .appoint_head {
        background-color: var(--darkbluecolorone);

        .txt_appoint_head {
          color: var(--whitecolor);
          text-align: center;
          display: block;
          font-size: var(--fs16);
          font-weight: var(--fw500);
          letter-spacing: 0.32px;
          line-height: 20px;
          padding: 30px;
        }
      }

      .appoint_body {
        padding: 40px;

        .list_type {
          display: flex;
          flex-direction: column;

          li {
            &:not(:last-child) {
              margin: 0 0 20px;
            }

            .txt_lbl {
              font-size: var(--fs14);
              font-family: var(--fffira);
              color: var(--blackcolorone);
              line-height: 18px;
              display: block;
              margin: 0 0 10px;
            }

            .field_input {
              border: 1px solid var(--lightgraycolorthirteen);
              height: 40px;
              @include border-radius(5px);
              font-family: var(--fffira);
              font-weight: var(--fw500);
              font-size: var(--fs16);
              color: var(--blackcolorone);
              width: 100%;
              padding: 0 20px;
            }

            .custom_select {
              .nice-select {
                width: 200px;
                border: 1px solid var(--lightgraycolorthree);
                @include border-radius(4px);
                font-size: var(--fs14);
                font-weight: var(--fw500);
                font-family: var(--fffira);
                color: var(--blackcolorone);

                &:after {
                  @include transform-origin(initial);
                  @include transform(rotate(0));
                  border-top: 10px solid var(--lightgraycolorfour);
                  border-right: 6px solid transparent;
                  border-left: 6px solid transparent;
                  border-bottom: initial;
                }

                &.open {
                  &:after {
                    @include transform(rotate(60deg));
                  }
                }

                .list {
                  width: 200px;
                }
              }
            }
          }
        }

        .txt_summary {
          color: var(--blackcolorone);
          display: block;
          font-size: var(--fs16);
          line-height: 22px;
        }
      }
    }

    .btn_block {
      &.btn_block_save_active {
        display: flex !important;
      }
    }
  }
}

/*BELOW CODE FOR BODY CONTENT - LOGIN AND REGISTRATION PAGE*/
.body_content {
  min-height: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logo {
    text-align: center;
    margin: 0 auto 30px;
  }

  .form_content {
    width: 100%;
    /*BELOW CODE FOR REGISTRATION PAGE*/
  }
}

/*BELOW CODE FOR MODAL DESIGN ANYWAY*/
.custom_modal {
  .custom_modal_big_width {
    max-width: 1300px;
  }

  .custom_modal_dialog_normal_width {
    max-width: 933px;
  }

  .custom_modal_dialog_medium_width {
    max-width: 933px;
  }

  .custom_modal_small_width {
    max-width: 650px;
  }

  .modal-content {
    @include border-radius(15px);

    .modal-header {
      .modal-title {
        color: var(--blackcolortwo);
        font-size: var(--fs22);
        font-weight: var(--fw500);
        font-family: var(--fffira);
        letter-spacing: 0.33px;
      }
    }

    .modal-body {
      .patient_reg_content {
        .field_group {
          .field_lbl {
            color: var(--blackcolor);
            font-size: var(--fs16);
            font-weight: var(--fw400);
            font-family: var(--fffira);
            display: block;
          }

          .field_input {
            border: 1px solid var(--lightgraycolorthirteen);
            height: 40px;
            @include border-radius(5px);
            font-family: var(--fffira);
            font-weight: var(--fw500);
            font-size: var(--fs16);
            color: var(--blackcolorone);
            width: 100%;
            padding: 0 20px;
          }

          .txt_error {
            color: var(--redcolorone);
            font-family: var(--fffira);
            font-weight: var(--fw400);
            font-size: var(--fs16);
            display: block;
          }
        }
      }

      .box_radio {
        .list_radio {
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin: 0 50px 0 0;
            }

            label {
              cursor: pointer;
              display: flex;
              align-items: center;

              input {
                display: none;

                &:checked {
                  ~ .dot {
                    background-color: var(--darkbluecolorone);
                    border-color: var(--graycolorone);
                  }
                }
              }

              .dot {
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: middle;
                margin: 0 15px 0 0;
                border: 1px solid var(--lightgraycolorone);
                @include border-radius(50%);
                @include transition(all, 0.3s, linear);
              }

              .txt {
                color: var(--blackcolorone);
                font-family: var(--fffira);
                font-size: var(--fs14);
              }
            }
          }
        }
      }

      .list_item {
        margin: 0 0 30px;

        .txt_field {
          color: var(--blackcolor);
          font-size: var(--fs16);
          font-weight: var(--fw400);
          font-family: var(--fffira);
        }

        .link {
          font-size: var(--fs13);
          font-weight: var(--fw400);
          font-family: var(--fffira);
          @include border-radius(5px);
          display: inline-block;
          height: 30px;
          line-height: 30px;
          padding: 0 15px;

          &.link_danger {
            color: var(--redcolorone);
            background-color: var(--redcolortwo);
          }

          &.link_success {
            color: var(--lightgreencolortwo);
            background-color: var(--lightgreencolorone);
          }
        }
      }

      .headtitle {
        color: var(--blackcolorone);
        font-size: var(--fs25);
        font-weight: var(--fw500);
        font-family: var(--fffira);
        line-height: 40px;
      }

      &.custom_modal_body {
        .bigheadtitle {
          color: var(--blackcolorthree);
          font-size: var(--fs30);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          margin: 0 0 30px;
        }

        .desc {
          color: var(--blackcolorthree);
          font-size: var(--fs18);
          font-family: var(--fffira);
          line-height: 22px;
          margin: 0 0 40px;
        }

        .code_block {
          height: 94px;
          max-width: 200px;
          margin: 0 auto 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include border-radius(5px);
          background-color: var(--lightbluecolorseventeen);

          .txt_code {
            color: var(--darkbluecolorone);
            font-size: var(--fs30);
            font-weight: var(--fw600);
            font-family: var(--fffira);
          }
        }
      }

      .section_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 45px;
        background-color: var(--whitecolor);
        position: sticky;
        top: 0;
        z-index: 9;

        .icon {
          display: inline-block;
          margin: 0 10px 0 0;
        }

        .headtitle {
          color: var(--blackcolortwo);
          font-size: var(--fs25);
          font-weight: var(--fw500);
          font-family: var(--fffira);
        }

        .txt_date {
          color: var(--blackcolorfive);
          font-size: var(--fs18);
          font-weight: var(--fw500);
          font-family: var(--fffira);
        }
      }

      .upload_file_block {
        cursor: pointer;
        margin: 0 0 100px;

        .lbl_file_upload {
          cursor: pointer;
          background-color: var(--whitecolor);
          border: 1px solid var(--primarycolor);
          color: var(--primarycolor);
          padding: 0 20px;
          font-family: var(--fffira);
          @include border-radius(4px);
          cursor: pointer;
          font-size: var(--fs14);
          font-weight: var(--fw500);
          height: 40px;
          line-height: 40px;
        }

        .txt_file {
          margin-left: 0.3rem;
          display: inline-block;
          border: 1px solid var(--lightgraycolornine);
          text-align: center;
          font-family: var(--fffira);
          font-size: var(--fs14);
          height: 40px;
          padding: 0 20px;
          cursor: pointer;
          @include border-radius(4px);
          line-height: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .txt_desc {
        font-size: var(--fs22);
        color: var(--blackcolorone);
        line-height: 17px;
        margin: 0 0 20px;
      }

      .medicine_table {
        .custom_table {
          thead {
            tr {
              th {
                padding: 20px 0;
                border-bottom: 1px solid var(--lightgraycolorthree);
              }
            }
          }

          tbody {
            tr {
              td {
                border-bottom: 0;
              }
            }
          }
        }
      }

      .num_issuedate_head {
        margin: 0 0 50px;

        .num_block {
          display: flex;
          align-items: flex-end;

          .subtitle {
            color: var(--blackcolortwo);
            font-size: var(--fs22);
            font-weight: var(--fw500);
            letter-spacing: 0.37px;
            margin: 0 20px 0 0;
          }

          .txt_code {
            height: 42px;
            line-height: 42px;
            border: 1px solid var(--lightgraycolorthree);
            display: block;
            text-align: center;
            font-size: var(--fs14);
            color: var(--blackcolorone);
            @include border-radius(4px);
            display: block;
            padding: 0 40px;
          }
        }

        .issue_date_block {
          .txt_word_date {
            font-size: var(--fs16);
            color: var(--blackcolorone);
            display: inline-block;
            margin: 0 5px 0 0;
          }

          .txt_date {
            font-size: var(--fs16);
            font-weight: var(--fw500);
            color: var(--blackcolorone);
          }
        }
      }

      .to_by_block {
        margin: 0 0 50px;

        .txt_big {
          font-size: var(--fs16);
          color: var(--blackcolorone);
        }

        .txt_small {
          font-weight: var(--fw500);
        }
      }

      .invoice_format {
        .custom_table {
          thead {
            tr {
              th {
                padding: 20px;
                border-bottom: 1px solid var(--lightgraycolorthree);

                .txt {
                  color: var(--lightgraycolorfour);
                  font-size: var(--fs16);
                  letter-spacing: 0.27px;
                  font-weight: var(--fw400);
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 20px;
                border-bottom: 1px solid var(--lightgraycolorthree);

                .txt {
                  color: var(--lightgraycolorfour);
                  font-size: var(--fs16);
                  font-weight: var(--fw600);
                  letter-spacing: 0.27px;

                  &.txt_black_color {
                    color: var(--blackcolortwo);
                  }
                }
              }
            }
          }
        }
      }

      .list_information {
        padding: 30px;

        &.list_remove_spacing {
          padding: 0;
        }

        &.list_border_wo {
          li {
            &:not(:last-child) {
              border-bottom: 0;
            }
          }
        }

        > li {
          padding: 10px 0;

          &:not(:last-child) {
            margin: 0 0 10px;
            border-bottom: 1px solid var(--lightgraycolorthree);
          }

          .radio_block {
            margin: 0 0 30px;

            .list_radio {
              display: flex;
              align-items: center;

              li {
                &:not(:last-child) {
                  margin: 0 20px 0 0;
                }

                label {
                  cursor: pointer;
                  display: flex;
                  align-items: center;

                  input {
                    display: none;

                    &:checked {
                      ~ .dot {
                        background-color: var(--darkbluecolorone);
                        border-color: var(--graycolorone);
                      }
                    }
                  }

                  .dot {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 15px 0 0;
                    border: 1px solid var(--lightgraycolorone);
                    @include border-radius(50%);
                    @include transition(all, 0.3s, linear);
                  }

                  .txt {
                    color: var(--blackcolorone);
                    font-family: var(--fffira);
                    font-size: var(--fs14);
                    letter-spacing: 0.21px;
                  }
                }
              }
            }
          }

          .txt {
            font-size: var(--fs16);
            font-family: var(--fffira);
            letter-spacing: 0.24px;
            display: inline-block;
            color: var(--blackcolorone);
          }

          .txt_gray_color {
            color: var(--lightgraycoloreight);
            font-size: var(--fs14);
            font-weight: var(--fw500);
          }

          .field_input {
            border: 1px solid var(--lightgraycolorthirteen);
            height: 40px;
            @include border-radius(5px);
            font-family: var(--fffira);
            font-weight: var(--fw500);
            font-size: var(--fs16);
            color: var(--blackcolorone);
            width: 100%;
            padding: 0 20px;
          }

          .field_txtarea {
            border: 1px solid var(--lightgraycolorthirteen);
            height: 232px;
            @include border-radius(5px);
            font-family: var(--fffira);
            font-size: var(--fs16);
            color: var(--blackcolorone);
            width: 100%;
            padding: 20px;
            resize: none;
          }

          .text-muted {
            font-size: var(--fs12);
            color: var(--blackcolorone) !important;
          }

          .custom_select {
            .nice-select {
              width: 100%;
              font-weight: var(--fw500);
              color: var(--blackcolorone);

              .list {
                width: 100%;

                li {
                  padding: 0 10px;
                }
              }
            }
          }

          .custom_datepicker {
            .field_date {
              cursor: pointer;
            }
          }
        }
      }

      .list_items {
        border: 1px solid var(--lightgraycolorone);

        li {
          padding: 10px;

          &:not(:last-child) {
            border-bottom: 1px solid var(--lightgraycolorone);
          }

          a {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .txt {
              color: var(--blackcolorthree);
              font-size: var(--fs14);
              font-family: var(--fffira);
              text-transform: uppercase;
            }
          }
        }
      }

      .btn_block {
        .btn {
          &:not(:last-child) {
            margin: 0 10px 0 0;
          }
        }
      }

      .custom_scrollbar {
        height: 500px;
        overflow-y: scroll;

        &.vertical_scrollbar::-webkit-scrollbar {
          width: 6px;
          background-color: var(--lightgraycoloreleven);
        }

        &.vertical_scrollbar::-webkit-scrollbar-track {
          @include border-radius(10px);
          box-shadow: 0px 3px 6px #00000029;
          -webkit-box-shadow: 0px 3px 6px #00000029;
          background-color: var(--lightgraycoloreleven);
        }

        &.vertical_scrollbar::-webkit-scrollbar-thumb {
          @include border-radius(10px);
          box-shadow: 0px 3px 6px #00000029;
          -webkit-box-shadow: 0px 3px 6px #00000029;
          background-color: var(--lightgraycolortwelve);
        }

        .num_title_block {
          display: flex;
          align-items: center;
          background-color: var(--whitecolor);
          z-index: 9;
          margin: 0 0 10px;

          .txt_num {
            width: 54px;
            height: 54px;
            background-color: var(--darkbluecolorone);
            color: var(--whitecolor);
            font-size: var(--fs23);
            font-weight: var(--500);
            display: flex;
            align-items: center;
            justify-content: center;
            @include border-radius(3px);
            cursor: pointer;
            margin: 0 10px 0 0;
          }

          .txt_word {
            color: var(--blackcolorthree);
            font-size: var(--fs17);
            font-weight: var(--500);
            display: block;
          }
        }

        .modal_table_content {
          .custom_table {
            border: 1px solid var(--lightgraycolorthree);

            thead {
              tr {
                th {
                  vertical-align: middle;
                  border-bottom: 1px solid var(--lightgraycolorthree);

                  &:nth-child(1) {
                    text-align: center;
                  }

                  .txt {
                    font-size: var(--fs14);
                    font-weight: var(--fw500);
                    color: var(--blackcolortwo);
                    font-family: var(--fffira);
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  border-bottom: 1px solid var(--lightgraycolorthree);
                  vertical-align: middle;

                  &:nth-child(1) {
                    text-align: center;
                  }

                  .txt {
                    font-size: var(--fs13);
                    color: var(--blackcolorfive);
                    font-family: var(--fffira);
                  }
                }
              }
            }
          }
        }
      }

      .fill_form_content {
        padding: 0 30px;

        .headingtitle {
          color: var(--blackcolortwo);
          font-size: var(--fs20);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          margin: 0 0 30px;
        }

        .txt_title {
          color: var(--blackcolorfive);
          font-size: var(--fs16);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          margin: 0 0 20px;
        }

        .maintitle {
          color: var(--blackcolortwo);
          font-size: var(--fs22);
          font-weight: var(--fw500);
          font-family: var(--fffira);
          letter-spacing: 0.27px;
          margin: 0 0 20px;
        }

        &.half_width {
          max-width: 420px;
        }

        .list_user_pwd {
          li {
            &:not(:last-child) {
              margin: 0 0 30px;
            }

            a {
              color: var(--primarycolor);
              font-size: var(--fs16);
              font-weight: var(--fw500);
              font-family: var(--fffira);
            }
          }
        }

        .list_bank_details {
          li {
            display: flex;
            align-items: center;

            &:not(:last-child) {
              margin: 0 0 20px;
            }

            .txt {
              font-size: var(--fs16);
              font-family: var(--fffira);
              color: var(--blackcolorfive);
              display: inline-block;
              width: 50%;
            }
          }
        }

        .field_group {
          margin: 0 0 20px;
          position: relative;

          .field_lbl {
            color: var(--blackcolortwo);
            font-size: var(--fs14);
            font-family: var(--fffira);
            display: block;
            margin: 0 0 9px;
          }

          .field_txtarea {
            resize: none;
            overflow: hidden;
            height: 67px !important;
          }

          .field_input,
          .field_txtarea {
            border: 1px solid var(--lightgraycolorone);
            height: 40px;
            @include border-radius(5px);
            font-family: var(--fffira);
            font-weight: var(--fw500);
            font-size: var(--fs16);
            color: var(--blackcolorone);
            width: 100%;
            padding: 0 20px;
          }

          .icon {
            position: absolute;
            top: 35px;
            right: 20px;
            cursor: pointer;
          }

          .txt_muted {
            color: var(--lightgraycolorseven);
            font-size: var(--fs13);
            font-family: var(--fffira);
            display: block;
            margin: 10px 0 0;

            &.txt_green_color {
              color: var(--greencolorone);
            }
          }
        }

        .btn_block {
          .btn {
            &:not(:last-child) {
              margin: 0 10px 0 0;
            }
          }
        }
      }

      .info_modal_content {
        padding: 0 30px;

        .subtitle {
          font-size: var(--fs18);
          font-weight: var(--fw600);
          font-family: var(--fffira);
          line-height: 36px;
          letter-spacing: 0.27px;
          margin: 0 0 5px;
          color: var(--blackcolortwo);
        }

        .field_date {
          border: 1px solid var(--lightgraycolorone);
          height: 40px;
          @include border-radius(5px);
          font-family: var(--fffira);
          font-weight: var(--fw500);
          font-size: var(--fs16);
          color: var(--blackcolorone);
          width: auto;
          padding: 0 20px;
          margin: 0 0 0 10px;
        }

        .list_info {
          margin: 0 0 50px;

          li {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
              margin: 0 0 10px;
            }

            .icon_dot {
              position: relative;
              top: 5px;
              width: 11px;
              height: 11px;
              @include border-radius(50%);
              background-color: var(--blackcolortwo);
              border: 1px solid var(--graycolorone);
              margin: 0 10px 0 0;
              float: left;
            }

            .txt {
              font-size: var(--fs18);
              font-weight: var(--fw600);
              font-family: var(--fffira);
              color: var(--blackcolortwo);
              width: calc(100% - 12px);
            }
          }
        }

        .txt_confirm {
          font-size: var(--fs18);
          font-weight: var(--fw600);
          font-family: var(--fffira);
          color: var(--blackcolortwo);
          line-height: 22px;
          text-align: center;
          display: block;
          margin: 0 0 50px;
        }

        .btn_block {
          .btn {
            &:not(:last-child) {
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}

/*.fc td,.fc th{
	line-height: 70px;
}*/

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: initial;
  text-align: center;
}

.primary-text {
  color: var(--primarycolor);
  margin-bottom: 2em;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.3;
}
