/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cd-slot-container {
  padding-top: 1em;
  margin: auto;
  width:80%;
  height:55px;
}

.cd-slots-full {
  /*padding-top: 0.5em;*/
  /*
  background-color: #e5e5f7;
  opacity: 0.6;
  background: repeating-linear-gradient( -45deg, #f74e45, #f74e45 5px, #e5e5f7 5px, #e5e5f7 25px );
   */
  background-color: #E43D40;
  cursor: pointer;
}

.cd-slots-almost-full {
  /*padding-top: 0.5em;*/
  background-color: #FFEDDD;
  cursor: pointer;
}

.cd-slots-available {
  /*padding-top: 0.5em;*/
  background-color: #A5EACA;
  cursor: pointer;
}

.cd-slots-nostyle {
  /*padding-top: 0.5em;*/
}

.cd-slots-default {
  text-align: center;
  line-height: 3em;
  width:100%;
  height:100%;
  font-size: 1.6em;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.main_content .tab_wrapper .tab_body_block .appoinment_step_block .actions ul li a, .main_content .tab_wrapper .tab_body_block .appoinment_step_block .actions ul li button {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: var(--primarycolor);
  color: var(--whitecolor);
  font-size: var(--fs18);
  font-family: var(--fffira);
  font-weight: 500;
  border-radius: 6px;
  border: 0;
  outline: none;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  text-align: center;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary_button {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: var(--primarycolor);
  color: var(--whitecolor);
  font-size: var(--fs18);
  font-family: var(--fffira);
  font-weight: 500;
  border-radius: 6px;
  border: 0;
  outline: none;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  text-align: center;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary_button_disabled {
  cursor: not-allowed;
  position: relative;
  overflow: hidden;
  background-color: var(--primarycolor);
  color: var(--whitecolor);
  font-size: var(--fs18);
  font-family: var(--fffira);
  font-weight: 500;
  border-radius: 6px;
  border: 0;
  outline: none;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  text-align: center;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_tab_header {
  background-color: var(--lightbluecolorthree) !important;
  padding: 10px 30px !important;
  color: var(--darkbluecolorone) !important;
  font-size: var(--fs18) !important;
  font-weight: var(--fw600) !important;
  letter-spacing: .33px !important;
  border-radius: 5px 5px 0 0 !important;
  text-align: center !important;
  white-space: nowrap !important;
  text-transform: none !important;
}

.Mui-selected {
   background-color: var(--darkbluecolorone) !important;
   color: var(--whitecolor) !important;
}