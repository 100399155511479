/*BELOW CODE FOR UPLOAD PHOTO PAGE*/
.upload_content{
	.section_title{
		margin: 0 0 30px;
		text-align: center;
		.headtitle{
			color: var(--blackcolor);
			font-size: var(--fs20);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 24px;
		}
		.link{
			color: var(--primarycolor);
			text-transform: uppercase;
			font-size: var(--fs16);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
		}
	}
	.box_upload{
		.thumb_upload{
			width: 160px;
			height: 160px;
			@include border-radius(50%);
			border: 1px solid var(--graycolorone);
			margin: 0 auto 20px;
			cursor: pointer;
			overflow: hidden;
			.img_upload_photo{
				width: 100%;
				height: 100%;
				@include border-radius(50%);
				object-fit: cover;
			}
		}
		.field_upload{
			text-align: center;
			margin: 0 0 37px;
			.custom_lbl{
				margin: 0 auto 12px;
				height: 40px;
				@include border-radius(6px);
				width: 200px;
				border: 1px solid var(--primarycolor);
				background-color: var(--whitecolor);
				color: var(--primarycolor);
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				@include transition(all,.3s,linear);
				position: relative;
				overflow: hidden;
				z-index: 1;
				&:hover{
					color: var(--whitecolor);
					.icon{
						img{
							filter: brightness(0) invert(1);
						}
					}
					&:after{
						@include transform(scaleX(1));	
					}
				}
				&:after{
					content: "";
					position: absolute;
					inset: 0;
					@include transition(all,.3s,linear);
					background-color: var(--primarycolor);
					border: 1px solid var(--primarycolor);
					color: var(--whitecolor);
					@include transform(scaleX(0));
					z-index: -1;
				}
				input{
					display: none;
				}
				.icon{
					display: inline-block;
					margin: 0 8px 0 0;
				}
				.txt{
					font-size: var(--fs16);
					font-weight: var(--fw500);
					font-family:  var(--fffira);
				}
			}
			.txt_upload{
				color: var(--blackcolorone);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				display: block;
			}
		}
		.box_message{
			margin: 0 0 40px;
			.txt_detail{
				color: var(--blackcolorone);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				display: block;
				margin: 0 0 10px;
			}
			.field_txtarea{
				border: 1px solid var(--lightgraycolorthree);
				@include border-radius(5px);
				height: 122px;
				width: 100%;
				overflow: hidden;
				resize: none;
				color: var(--blackcolorone);
				font-size: var(--fs14);
				font-family:  var(--fffira);
				padding: 10px;
			}
		}

	}
}