.nice-select{
  width: 100%;
	.list{
		width: 100%;
	}
}

/*For chrome, Safari, Edge, Opera :*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/*For firefox :*/
input[type=number]{
    -moz-appearance: textfield;
}