/*BELOW CODE FOR LOGIN PAGE*/
/*BELOW CODE FOR LOGIN PAGE*/
.login_content{
	background-color: var(--secondarycolor);
	@include border-radius(10px);
	margin: auto;
	max-width: 540px;
	padding: 50px;
	width: 100%;
	.title_block{
		margin: 0 0 30px;
		text-align: center;
		.headtitle{
			color: var(--whitecolor);
			font-size: var(--fs40);
			font-weight: var(--fw500);
			font-family:  var(--fffira);
			line-height: 48px;
		}
	}
	.fill_form_content{
			.field_group{
				position: relative;
				&:not(:last-child){
					margin: 0 0 20px;
				}
				.field_lbl{
					color: var(--whitecolorone);
					font-family:  var(--fffira);
					font-size: var(--fs14);
					display: block;
					margin: 0 0 10px;
				}
				.field_input{
					background-color: var(--whitecolor);
					@include border-radius(5px);
					border: 1px solid var(--graycolorone);
					height: 40px;
					width: 100%;
					font-family:  var(--fffira);
					font-size: var(--fs14);
					padding: 0 40px 0 12px;
				}
				.icon{
					position: absolute;
					top: 37px;
					right: 0;
					width: 30px;
					height: 40px;
					cursor: pointer;
				}
			}
			.link{
				color: var(--primarycolor);
				font-family:  var(--fffira);
				font-size: var(--fs14);
				display: block;
				margin: 0 0 20px;
			}
			.link_account{
				margin-top: 40px;
				color: var(--primarycolor);
				font-family:  var(--fffira);
				font-size: var(--fs16);
				font-weight: var(--fw600);
				display: block;
				text-align: center;
			}
		}
}