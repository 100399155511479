/*BELOW CODE FOR ADD CLICNIC PAGE*/
.add_clinic_content{
	.subtitle{
		color: var(--blackcolorthree);
		font-size: var(--fs20);
		font-family: var(--fffira);
		line-height: 24px;
		letter-spacing: 0.4px;
		margin: 0 0 40px;
	}
	.box_add_clinic{
		max-width: 400px;
		margin: 0 0 100px;
		.list_add_clinic_item{
			max-width: 400px;
			margin: 0 0 30px;
			li{
				&:not(:last-child){
					margin: 0 0 30px;
				}
				.field_input{
							border: 1px solid var(--lightgraycolorthree);
							@include border-radius(5px);
							padding: 0 10px;
							color: var(--blackcolorthree);
							font-size: var(--fs14);
							font-family: var(--fffira);
							font-weight: var(--fw500);
							height: 40px;
							width: 100%;
						}
					.icon_delete{
						border: 1px solid var(--lightgraycolorthree);
						@include border-radius(5px);
						width: 100%;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.btn_outline {
						font-size: var(--fs16);
					}
				
			}
		}
	}
	
}