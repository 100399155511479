/*GOOGLE FONT*/
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700;900&display=swap');

:root{
    --primarycolor: #F78F2D;
    --secondarycolor: #0E426A;
    --darkbluecolorone: #0060A6;
    --whitecolor: #fff;
    --blackcolor: #000;
    --blackcolorone: #000000CC;
    --blackcolortwo: #000000E5;
    --blackcolorthree: #2B2B2B;
    --blackcolorfour: #1A1A1A;
    --blackcolorfive: #000000CC;
    --blackcolorsix: #424242;
    --blackcolorseven: #000000E6;
    --blackcoloreight: #020202;
    --lightbluecolorone: #F5F8FA;
    --lightbluecolortwo: #0060A6B3;
    --lightbluecolorthree: #EAF5FE;
    --lightbluecolorfour: #9FCDEF;
    --lightbluecolorfive: #F5F9FD;
    --lightbluecolorsix: #97D1FA57;
    --lightbluecolorseven: #0060A699;
    --lightbluecoloreight: #DDECF8;
    --lightbluecolornine: #EAF6FE;
    --lightbluecolorten: #97D1FA33;
    --lightbluecoloreleven: #97D1FA;
    --lightbluecolortwelve: #CEE6F7;
    --lightbluecolorthirteen: #0060A633;
    --lightbluecolorfourteen: #97D1FA36;
    --lightbluecolorsixteen: #CBE5F8;
    --lightbluecolorseventeen: #97D1FA;
    --graycolorone: #707070;
    --graycolortwo: #08080899;
    --graycolorthree: #000000B2;
    --lightgraycolorone: #0000004C;
    --lightgraycolortwo: #DDDDDD;
    --lightgraycolorthree: #00000026;
    --lightgraycolorfour: #00000099;
    --ligthgraycolorfive: #00000029;
    --lightgraycolorsix: #F2F2F2;
    --lightgraycolorseven: #00000066;
    --lightgraycoloreight: #08080866;
    --lightgraycolornine: #00000033;
    --lightgraycolorten: #0000000D;
    --lightgraycoloreleven: #F3F3F3;
    --lightgraycolortwelve: #D1D1D1;
    --lightgraycolorthirteen: #e8e8e8;
    --lightgraycolorfourteen: #70707047;
    --lightgraycolorfifteen: #EFEFEF66;
    --lightgraycolorsixteen: #00000019;
    --lightgraycolorseventeen: #00000038;
    --lightgraycoloreighteen: #4444448F;
    --whitecolorone: #FFFFFFCC;
    --greencolorone: #16A947CC;
    --greencolortwo: #11A307;
    --lightgreencolorone: #E8FFF3;
    --lightgreencolortwo: #50CD89;
    --lightorangecolorone: #FFE4CA;
    --lightorangecolortwo: #F78F2D66;
    --lightorangecolorthree: #F78F2D33;
    --lightorangecolorfour: #FCE5D0;
    --lightorangecolorfive: #FFF3E8;
    --lightorangecolorsix: #FEE9D5;
    --lightorangecolorseven: #ffeddd;
    --lightorangecoloreight: #e4d6ca;
    --redcolorone: #F1416C;
    --redcolortwo: #FFE7ED;
    --lightgreencolor1: #a5eaca;
    --lightgreencolor2: #bbf3b5;

    /*//=== FONT FAMILY ===//*/
    --fffira:'Fira Sans', sans-serif;

    /*//==== FONT SIZE ====//*/
    --fs9:9px;
    --fs10:10px;
    --fs11:11px;
    --fs12:12px;
    --fs13:13px;
    --fs14:14px;
    --fs15:15px;
    --fs16:16px;
    --fs17:17px;
    --fs18:18px;
    --fs20:20px;
    --fs22:22px;
    --fs23:23px;
    --fs24:24px;
    --fs25:25px;
    --fs26:26px;
    --fs28: 28px;
    --fs30: 30px;
    --fs32:32px;
    --fs33:33px;
    --fs34:34px;
    --fs37:37px;
    --fs40: 40px;
    --fs42: 42px;
    --fs43: 43px;
    --fs50: 50px;
    --fs52: 52px;
    --fs70: 70px;
    --fs100: 100px;

    /*FONT WEIGHT*/
    --fw100: 100;
    --fw200: 200;
    --fw300: 300;
    --fw400: 400;
    --fw500: 500;
    --fw600: 600;
    --fw700: 700;
    --fw800: 800;
    --fw900: 900;
}


